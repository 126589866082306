import { Box, Dialog, Stack, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useBlocks } from "../../../helper/useAPIs";
import DefaultPaper from "../../DefaultPaper";
import BlockLibrarySidebarItem from "./BlockLibrarySidebarItem";

const BlockLibrarySidebarGroup = ({ title, blueprints }) => {
    const formattedTitle = title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
    return (
        <Stack direction="column" spacing={2}>
            <Typography variant="h6">{formattedTitle}</Typography>
            {blueprints.map((block_blueprint, index) => (
                <BlockLibrarySidebarItem block_blueprint={block_blueprint} key={index} />
            ))}
        </Stack>
    )
}

const BlockLibrarySidebarComponent = ({ height }) => {
    const { data: blockBlueprintGroups = [] } = useBlocks();

    return (
        <Box sx={{ mt: 0 }}>
            <DefaultPaper>
                <Stack direction="column" >
                    <Box sx={{
                        paddingBottom: "10px",
                        borderBottom: '1px solid',
                        borderColor: 'divider'
                    }}>
                        <Tooltip title="You can drag these nodes onto the canvas.">
                            <Typography variant="h5" >Block Library</Typography>
                        </Tooltip>
                    </Box>
                    <Stack
                        direction="column"
                        spacing={2}
                        sx={{
                            pt: 2,
                            display: "flex",
                            height: height - 150,
                            overflow: "hidden",
                            overflowY: "scroll",
                        }}
                    >
                        {Object.entries(blockBlueprintGroups).map(([group, block_blueprints], index) => (
                            <BlockLibrarySidebarGroup title={group} blueprints={block_blueprints} key={index} />
                        ))}
                    </Stack>
                </Stack>
            </DefaultPaper>
        </Box>
    );
};

export default BlockLibrarySidebarComponent;