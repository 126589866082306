import React, { useState, useContext } from 'react';
import { Button, Box, Typography, Stack } from '@mui/material';
import DataLoadingTableComponent from '../table/DataLoadingTableComponent';
import LoadingSnackbar from 'components/snackbars/LoadingSnackbar';
import LocalStorageService from 'helper/LocalStorageService';
import { Download } from '@mui/icons-material';

const ExportPageComponent = ({ pipeId, nodeId, usernameFromPath, reload, showTable = true }) => {
    const [isExporting, setIsExporting] = useState(false);

    const handleExport = () => {
        setIsExporting(true);
        let token = LocalStorageService().getAccessToken()
        let url = `${window.REACT_APP_API_ENDPOINT}user/${usernameFromPath}/pipe/${pipeId}/export/${nodeId}?jwt=${token}`
        window.location.replace(url)
    };

    return (
        <Stack direction="column" spacing={2}>
                <Typography variant="body">Clicking the button will download a ZIP file containing the data as Excel file and all plots as SVG files. Please note that this process could take a minute depending on your data.</Typography>
                <Button 
                    startIcon={<Download />}
                    variant="contained" 
                    onClick={handleExport} 
                    disabled={isExporting}
                    sx={{ width: 'fit-content' }}
                >
                    Export
                </Button>
            {showTable && (
                <Box sx={{ width: '100%', mt: 3 }}>
                    <DataLoadingTableComponent 
                        pipeId={pipeId} 
                        nodeId={nodeId} 
                        username={usernameFromPath} 
                        reloadPipeCounter={reload}
                    />
                </Box>
            )}
            {/* <LoadingSnackbar loading={isExporting} /> */}
        </Stack>
    );
};

export default ExportPageComponent;
