import { Box } from "@mui/material";
import Logger from "helper/Logger";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { ApiContext } from "../../helper/ApiContext";
import BlockPageComponent from "components/PageComponents/BlockPageComponent";

const DashboardComponent = React.memo(({ pipeId, usernameFromPath }) => {

    const { api } = useContext(ApiContext);
    const [pipe, setPipe] = useState({});
    const [reloadCounter, reload] = useState(0)
    const branchMapRef = useRef({});
    const [expandedBlocks, setExpandedBlocks] = useState([]);

    useEffect(() => {
        api.getUserPipe(pipeId, usernameFromPath)
            .then((response) => {
                const pipe = response.data
                setPipe(pipe)
                if (Object.keys(branchMapRef.current).length === 0) {
                    api.getBranchMap(pipeId, usernameFromPath)
                        .then(response => {
                            const branchData = response.data;
                            branchMapRef.current = branchData;
                        })
                        .catch(error => {
                            Logger.error("Could not fetch branch map: " + JSON.stringify(error));
                        });
                }
            }).catch((error) => {
                Logger.error("Could not get user pipe: " + JSON.stringify(error))
            })
    }, [pipeId, reloadCounter])

    useEffect(() => {
        if (pipe && pipe.blocks) {
            setExpandedBlocks(pipe.blocks.map((block) => block.flow_id))
        }
    }, [pipe])

    const toggleBlockExpansion = (blockId) => {
        setExpandedBlocks((prevExpandedBlocks) => {
            if (prevExpandedBlocks.includes(blockId)) {
                return prevExpandedBlocks.filter((id) => id !== blockId);
            } else {
                return [...prevExpandedBlocks, blockId];
            }
        })
    }

    const onSelectionChanged = () => {
        reload(reloadCounter + 1)
    }

    const blocks = useMemo(() => {
        if (pipe.blocks === undefined) 
            return []
        let fileImportBlock = pipe.blocks?.find((block) => block.blueprint.type === "import")
        if (fileImportBlock === undefined) {
            Logger.error("Could not find file import block")
            return []
        }
        let sortedBlocks = []
        let visitedBlocks = []
        let queue = [fileImportBlock]
        while (queue.length > 0) {
            let block = queue.shift()
            if (visitedBlocks.includes(block.flow_id)) 
                continue
            visitedBlocks.push(block.flow_id)
            sortedBlocks.push(block)
            let connections = pipe.connections.filter((connection) => connection.source_flow_id === block.flow_id)
            connections.forEach((connection) => {
                let targetBlock = pipe.blocks.find((block) => block.flow_id === connection.target_flow_id)
                if (targetBlock !== undefined) 
                    queue.push(targetBlock)
            })
        }
        let filteredBlocks = sortedBlocks.filter((block) => block.dashboard_configuration.view_in_dashboard)
        return filteredBlocks
    }, [pipe.blocks])

    const { sidebarFilterBlock, mainBlocks, sideFilterBlockId, filterCategoriesBlocks } = useMemo(() => {
        const filterCategoriesBlocks = blocks.filter(block => block.blueprint.type === "filter_categories");
        let sidebarFilterBlock = [];
        let mainBlocks = [...blocks]; 
        let sideFilterBlockId = null;
        
        if (filterCategoriesBlocks.length > 0) {
            filterCategoriesBlocks.sort((a, b) => a.order - b.order);
            const firstFilterBlock = filterCategoriesBlocks[0];
            sidebarFilterBlock = [firstFilterBlock];
            sideFilterBlockId = firstFilterBlock.flow_id;
            mainBlocks = mainBlocks.filter(block => block !== firstFilterBlock);
        }
        return { sidebarFilterBlock, mainBlocks, sideFilterBlockId, filterCategoriesBlocks };
    }, [pipe.blocks]);

    const use80percentWidth = (filterCategoriesBlocks.length > 0)
    
    const renderDashedLine = () => (
        <div style={{
            borderTop: '2px solid gray',
            margin: '20px 0',
            width: '100%'
        }} />
    );

    const blockCellComponent = (block, index) => {
        return (
            <BlockPageComponent
                key={index}
                pipeId={pipeId}
                nodeId={parseInt(block.flow_id)}
                usernameFromPath={usernameFromPath}
                reload={reloadCounter}
                showTable={false}
                isDashboardItem={true}
                onSelectionChanged={onSelectionChanged}
                expanded={expandedBlocks.includes(block.flow_id)}
                toggleExpansion={() => toggleBlockExpansion(block.flow_id)}
                use80percentWidth={use80percentWidth}
                isFilterCategoriesSideBlock={block.flow_id === sideFilterBlockId}
            />
        );
    }

    const renderBlocksWithBranchSeparators = (someBlocks) => {
        const blocksByBranch = someBlocks.reduce((acc, block) => {
            const blockBranch = branchMapRef.current[block.flow_id] || block.order; 
            if (!acc[blockBranch]) {
                acc[blockBranch] = [];
            }
            acc[blockBranch].push(block);
            return acc;
        }, {});
    
        return Object.keys(blocksByBranch).map((branch, branchIndex) => {
            const branchBlocks = blocksByBranch[branch];
            return (
                <React.Fragment key={branch}>
                    {branchIndex !== 0 && branch > 2 && renderDashedLine()} 
                    {branchBlocks.map((block, index) => blockCellComponent(block, index))}
                </React.Fragment>
            );
        });
    };

    useEffect(() => {
        if (use80percentWidth) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
        return () => {
            document.body.style.overflow = "";
        };
    }, [use80percentWidth]);
    
    return (
        <>
            {use80percentWidth ? (
                <Box sx={{ mt: 4.2, display: 'flex', flexDirection: 'row', height: 'calc(100vh - 30px)' }}>
                    <Box 
                        sx={{ 
                            width: "16vw",
                            paddingTop: 3,
                            paddingBottom: 5,
                            overflowY: 'auto',
                        }}
                    >
                        {renderBlocksWithBranchSeparators(sidebarFilterBlock)}
                    </Box>
                    <Box 
                        sx={{ 
                            width: "81vw",
                            flex: 1, 
                            paddingLeft: 1, 
                            paddingTop: 3,
                            paddingBottom: 6.2,
                            overflowY: 'auto', 
                        }}
                    >
                        {renderBlocksWithBranchSeparators(mainBlocks)}
                    </Box>
                </Box>               
            ) : (
                <Box sx={{ mt: 7, flexDirection: 'column' }}>
                    {renderBlocksWithBranchSeparators(mainBlocks)}
                </Box>
            )}
        </>
    );
    
    
})

export default DashboardComponent