import { Stack, Typography, Box, Button, Tooltip } from "@mui/material";
import DataLoadingTableComponent from "components/table/DataLoadingTableComponent";
import { ApiContext } from "helper/ApiContext";
import { useContext, useEffect, useState } from "react";
import VegaUtils from "../../helper/VegaUtils";
import PreviewLoadingPlotComponent from "./PreviewLoadingPlotComponent";
import { SpecLoadingPlotComponent } from "./SpecLoadingPlotComponent";
import { usePresentationMode } from 'helper/PresentationModeContext';
import { createPortal } from "react-dom";
import { FilterList } from "@mui/icons-material";
import useFeatureFlags from "helper/useFeatureFlags";

const BrushSelectionListPlotComponent = ({ pipeId, nodeId, reloadCounter, onSelectionChanged, usernameFromPath, loadStaticPlot = false, showTable = true , isDashboardItem, use80percentWidth}) => {
    const vegaUtils = VegaUtils();
    const { api } = useContext(ApiContext);
    const [reloadTableCounter, reloadTable] = useState(0)
    const [plotDescriptions, setPlotDescriptions] = useState([]);
    const [currentSignals, setCurrentSignals] = useState([]); // used for diffing
    const [canvasWidth, setCanvasWidth] = useState(0);
    const [signalsChanged, setSignalsChanged] = useState([])
    const { isPresentationMode } = usePresentationMode();
    const { enableVegaLabelsTransform } = useFeatureFlags();
    var brushTimer = undefined;
    var skipNextBrushUpdateCall = false;
    const descriptionFontSize = isPresentationMode ? '19px' : '14px';
    
    const handleImageWidthCalculated = (width) => {
        setCanvasWidth(width);
    };

    const debounceBrushEvent = (func) => {
        if (brushTimer) {
            return
        }
        brushTimer = setTimeout(function () {
            func()
            brushTimer = undefined;
        }, 1000)
    }

    const patchVegaSpec = (spec) => {
        // manually push a mouseup signal that we can listen to
        // NOTE: we could also manually listen on the actual mouseup events of the canvas, but Vega already does that for us with this signal
        
        if (spec.signals) {
            spec.signals.push({
                name: "mouseup",
                on: [{
                    events: "mouseup",
                    update: "event"
                }]
            })

            spec.signals.push({
                name: "click",
                on: [{
                    events: "click",
                    update: "event"
                }]
            })
        }

        if (enableVegaLabelsTransform && spec.marks && spec.marks.length > 0 && spec.marks[0].marks && spec.marks[0].marks.length > 0) {
            spec.marks[0].marks.forEach(mark => {
                if (mark.type === "text") {
                    mark.transform = [
                        {
                            "type": "label",
                            "size": [1000, 1000]
                        }
                    ]
                }
            })
        }
    }

    const onViewLoaded = (view) => {
        let signals = vegaUtils.getCurrentBrushSelectionsFromView(view)
        Object.keys(signals).forEach(signal => {
            view.addSignalListener(signal, (event, value) => {
                debounceBrushEvent(() => {
                    updateSelectedDataPoints(view)
                })
            })
        })

        if (vegaUtils.getClickSignalFromView(view)) {
            view.addSignalListener("click", (event, value) => {
                // if we have an active brush selection, we don't want to update the selected data points
                if (activeUserSelections(view).length > 0) {
                    return
                }
                // if we click on the empty canvas nothing should happen
                if (value.item && value.item.tooltip) {
                    let singleDatapointTooltip = value.item.tooltip
                    let fakeSignal = {}
                    Object.keys(singleDatapointTooltip).forEach((key) => {
                        // convert to number
                        let value = parseFloat(singleDatapointTooltip[key])
                        if (!isNaN(value)) {
                            fakeSignal[key] = [value, value]
                        }
                    })
                    if (Object.keys(fakeSignal).length > 0) {
                        let signals = [fakeSignal]
                        setSignalsChanged(signals)
                    }
                }
            });
        }

        // run once to fill initially
        skipNextBrushUpdateCall = true
        updateSelectedDataPoints(view)

        // Set canvas width for description rendering
        const canvas = view.container();
        if (canvas) {
            setCanvasWidth(canvas.scrollWidth); 
        }
    }

    const activeUserSelections = (view) => {
        // NOTE: passing all the signals instead of just the changed one, because we need to filter on all selections
        let signals = vegaUtils.getCurrentBrushSelectionsFromView(view)
        let nonNullSignals = Object.entries(signals)
            .filter(([name, value]) =>
                value && // no undefined signals
                !Array.isArray(value) && // only dict signals
                Object.keys(value).length > 0) // no empty signals
            .map(([name, value]) => value)
        return nonNullSignals
    }

    const updateSelectedDataPoints = (view) => {
        if (skipNextBrushUpdateCall) {
            skipNextBrushUpdateCall = false
            return
        }

        let nonNullSignals = activeUserSelections(view)
        setSignalsChanged(nonNullSignals)
        if (!isDashboardItem && nonNullSignals !== currentSignals) {
            updatePipeWithSignals(nonNullSignals)
        }
    }

    const updatePipeWithSignals = (signals) => {
        if (!isNaN(pipeId) && !isNaN(nodeId) && signals) {
            api.updateNodeInPipe(pipeId, nodeId, usernameFromPath, signals)
                .then((response) => {
                    setCurrentSignals(signals)
                    onSelectionChanged()
                    reloadTable(prev => prev + 1)
                    return response
                })
        }
    }

    const splitDescriptions = plotDescriptions || []; 
    const descriptionWidth = canvasWidth / (splitDescriptions.length || 1);
    
    return (
        <Stack direction="column" spacing={2}>
            <Stack direction="column" sx={{ overflow: "scroll" }}>
                {(loadStaticPlot) ?
                    <PreviewLoadingPlotComponent
                        pipeId={pipeId}
                        nodeId={nodeId}
                        usernameFromPath={usernameFromPath}
                        reload={reloadCounter}
                        setPlotDescriptions={setPlotDescriptions}
                        onImageWidthCalculated={handleImageWidthCalculated}
                        use80percentWidth={use80percentWidth}
                    /> :
                    <SpecLoadingPlotComponent
                        pipeId={pipeId}
                        nodeId={nodeId}
                        reload={reloadCounter}
                        isDashboardItem={isDashboardItem}
                        usernameFromPath={usernameFromPath}
                        onViewLoaded={onViewLoaded}
                        patchVegaSpec={patchVegaSpec}
                        setPlotDescriptions={setPlotDescriptions}
                        use80percentWidth={use80percentWidth}
                    />
                }
                <Box display="flex" justifyContent="space-between">
                    {splitDescriptions.map((desc, index) => (
                        <Typography
                            key={index}
                            style={{
                                whiteSpace: 'pre-line',
                                minWidth: `${descriptionWidth}px`,
                                paddingLeft: '20px',
                                fontSize: descriptionFontSize
                            }}
                        >
                            {desc}
                        </Typography>
                    ))}
                </Box>
            </Stack>
            {showTable && <DataLoadingTableComponent
                pipeId={pipeId}
                nodeId={nodeId}
                username={usernameFromPath}
                reloadPipeCounter={reloadTableCounter}
            />}
            {isDashboardItem && document.getElementById(`block-${nodeId}-details-button-portal`) && createPortal(
                <>
                    {signalsChanged.length > 0 && <Tooltip title="Apply & Reload page">
                        <Button variant="contained" color="primary" onClick={(event) => {
                            event.stopPropagation()
                            updatePipeWithSignals(signalsChanged)
                        }} startIcon={<FilterList />}>
                            Apply
                        </Button>
                    </Tooltip>}
                </>,
                document.getElementById(`block-${nodeId}-details-button-portal`)
            )}
        </Stack>
    )
}

export default BrushSelectionListPlotComponent;