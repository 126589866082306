import { trimNumericalPrecisionTo4Digits } from 'helper/trimNumericalPrecisionTo4Digits';
import { MaterialReactTable } from 'material-react-table';
import { useEffect, useState, useMemo, useCallback, useContext } from 'react';
import { TextField } from '@mui/material';
import { ApiContext } from "../../helper/ApiContext";
import useFeatureFlags from 'helper/useFeatureFlags';
import { useIsForeignPipe } from 'helper/UrlUtils';

const DataTableComponent = ({
    data,
    pipeId,
    nodeId,
    username,
    block,
    config,
    reloadData = undefined,
    rowCount = undefined,
    isLoading = false
}) => {
    const { api } = useContext(ApiContext);
    const { currentUserIsAdmin } = useFeatureFlags();
    const isForeignPipe = useIsForeignPipe(username)
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const [tableHeaderMapping, setTableHeaderMapping] = useState({})
    const [editingColumnId, setEditingColumnId] = useState(null);
    const [tempHeaderName, setTempHeaderName] = useState('');

    const isShowTableBlock = block?.blueprint?.type === "show_table"
    const shouldAllowColumnRename = currentUserIsAdmin && isShowTableBlock && !isForeignPipe()

    useEffect(() => {
        if (isShowTableBlock && block?.data) {
            setTableHeaderMapping(block?.data?.value)
        }
    }, [block])

    const columnsFromRows = useCallback((data) => {
        if (data && data.length > 0) {
            return Object.keys(data[0]).filter((column) => !column.includes("Unnamed"))
        }
        return []
    }, []);

    const allColumns = useMemo(() => columnsFromRows(data), [data, columnsFromRows]);

    const filteredColumns = useMemo(() => {
        if (!data || data.length === 0) return [];
        let columnsToShow = allColumns;

        if (isShowTableBlock && config && config.elements) {
            const selectedColumns = config.elements.flatMap(element => element.selected_values || []);
            columnsToShow = selectedColumns.length > 0 ? selectedColumns : allColumns;
        }

        return columnsToShow;
    }, [data, config, isShowTableBlock, allColumns]);

    const [columnDefs, setColumnDefs] = useState(() =>
        filteredColumns.map((col) => ({
            accessorKey: col,
            header: col,
        }))
    );

    useEffect(() => {
        setColumnDefs((prevCols) => {
            return filteredColumns.map((col) => {
                const existing = prevCols.find(p => p.accessorKey === col);
                return existing ? existing : { accessorKey: col, header: col };
            });
        });
    }, [filteredColumns]);

    useEffect(() => {
        if (reloadData === undefined) {
            return
        }

        reloadData(
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sorting
        )
    }, [
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
    ]);

    const columnOrder = useMemo(() => {
        if (isShowTableBlock && config && config.elements) {
            const selectedValues = config.elements.flatMap(element => element.selected_values || []);
            const options = config.elements.flatMap(element => element.options || []);
            return selectedValues.length > 0 ? selectedValues : (options.length > 0 ? options : allColumns);
        }
        return allColumns;
    }, [allColumns, config, isShowTableBlock]);

    const startEditingHeader = (column) => {
        setEditingColumnId(column.id);
        setTempHeaderName(columnDefs.find(c => c.accessorKey === column.id)?.header || '');
    };

    const commitHeaderNameChange = () => {
        if (editingColumnId !== null && tempHeaderName.trim() !== '') {
            setColumnDefs((prev) =>
                prev.map((c) =>
                    c.accessorKey === editingColumnId
                        ? { ...c, header: tempHeaderName }
                        : c
                )
            )
            const newMapping = { ...tableHeaderMapping }
            const existingKey = Object.entries(newMapping).find(([key, value]) => value === editingColumnId)?.[0];
            if (existingKey) {
                newMapping[existingKey] = tempHeaderName.trim()
            } else {
                newMapping[editingColumnId] = tempHeaderName.trim()
            }

            api.renameColumnHeader({
                pipe_id: pipeId,
                node_id: nodeId,
                username: username,
                header_mapping: newMapping
            })
            .then((response) => {
                window.location.reload();
                setTableHeaderMapping(newMapping)
                cancelEditingHeader()
            })
            .catch((error) => {
                console.error("Failed to save column rename to backend:", error);
                cancelEditingHeader()
            });
        }
    };
    
    const cancelEditingHeader = () => {
        setEditingColumnId(null);
        setTempHeaderName('');
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            commitHeaderNameChange();
        } else if (e.key === 'Escape') {
            cancelEditingHeader();
        }
    };

    // Render header either as text or input based on state
    const renderHeader = useCallback(({ column }) => {
        const isEditing = editingColumnId === column.id;
        const currentDef = columnDefs.find(c => c.accessorKey === column.id);

        if (!currentDef) return column.columnDef.header;

        return (
            isEditing ? (
                <TextField
                    value={tempHeaderName}
                    onChange={(e) => setTempHeaderName(e.target.value)}
                    onBlur={commitHeaderNameChange}
                    onKeyDown={handleKeyDown}
                    autoFocus
                    size="small"
                    variant="standard"
                />
            ) : (
                <span
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    onDoubleClick={() => {
                        if (shouldAllowColumnRename) {
                            startEditingHeader(column);
                        }
                    }}
                    title={shouldAllowColumnRename ? "Double-click to rename" : ""}
                    style={{ cursor: shouldAllowColumnRename ? 'text' : 'default' }}
                >
                    {currentDef.header}
                </span>

            )
        );
    }, [editingColumnId, tempHeaderName, columnDefs]);
    
    return (
        <MaterialReactTable
            columns={columnDefs.map((colDef) => ({
                ...colDef,
                Header: renderHeader,
            }))}
            data={trimNumericalPrecisionTo4Digits(data)}
            columnOrder={columnOrder}
            enableStickyHeader
            enableTopToolbar={false}
            enableClickToCopy={false}
            enableColumnResizing
            enableFullScreenToggle={false}
            enableColumnPinning={true}

            // remote pagination section
            manualFiltering
            manualPagination
            manualSorting
            onColumnFiltersChange={setColumnFilters}
            onGlobalFilterChange={setGlobalFilter}
            onPaginationChange={setPagination}
            onSortingChange={setSorting}
            rowCount={rowCount}

            // row selection section
            selectAllMode={"all"}
            displayColumnDefOptions={{
                'mrt-row-select': { size: 20 },
                'mrt-row-actions': { size: 20 }
            }}

            // state management
            initialState={{
                density: 'compact',
                columnOrder: columnOrder,
                sorting: columnOrder.length > 0 ? [{ id: columnOrder[0], desc: false }] : []
            }}
            muiTablePaginationProps={{
                labelRowsPerPage: ""
            }}
            state={{
                columnOrder: columnOrder,
                columnFilters: columnFilters,
                globalFilter: globalFilter,
                pagination: pagination,
                showProgressBars: isLoading,
                sorting: sorting,
            }}
        />
    )
}

export default DataTableComponent;