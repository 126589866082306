import { Stack, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MultiSelectConfigList from "components/MultiSelectConfigList";
import { ApiContext } from 'helper/ApiContext';
import Logger from 'helper/Logger';
import { useContext, useEffect, useState } from 'react';

const DTypeMappingDrawerComponent = ({
    open,
    onClose,
    filename,
    isForeignPipe
}) => {

    const { api } = useContext(ApiContext)
    const [state, setState] = useState({})

    const hasMapping = state !== undefined && state.mapping !== undefined && Object.keys(state.mapping).length > 0

    useEffect(() => {
        if (filename !== undefined) {
            api.getFile(filename)
                .then((response) => {
                    setState(response.data.dtype_mapping)
                }).catch((error) => {
                    Logger.error(error)
                })
        }
    }, [filename])

    useEffect(() => {
        if (hasMapping && filename !== undefined) {
            // TODO: check if state actually changed
            api.updateFileDTypeMapping(filename, state.mapping, state.sources).then(() => {
                // TODO: notify parent?
            }).catch((error) => {
                Logger.error(error)
            })
        }
    }, [state])

    const handleSelectOption = (column, option) => {
        setState((state) => ({ 
            mapping: { ...state.mapping, [column]: option }, 
            sources: { ...state.sources, [column]: "user_specified" } 
        }))
    }

    return (
        <Drawer
            anchor={"right"}
            open={open}
            onClose={onClose}
        >
            {hasMapping && (
                <Box
                    sx={{ mt: 8, width: 250 }}
                role="presentation"
            >
                <List>
                    {Object.entries(state.mapping).map(([column, selectedValue], index) => (
                        <ListItem key={index} width="300px">
                            <Stack direction="column" sx={{ width: '100%' }}>
                                <Typography variant="h6">{column}</Typography>
                                <MultiSelectConfigList
                                    config={{ options: ["Number", "Date", "Text"], selected_values: [selectedValue] }}
                                    onSelectOption={(option) => {handleSelectOption(column, option)}}
                                    isForeignPipe={isForeignPipe}
                                    allowMultiple={false}
                                />
                            </Stack>
                        </ListItem>
                    ))}
                    </List>
                </Box>
            )}
        </Drawer>
    )
}

export default DTypeMappingDrawerComponent