import useWindowDimensions from "helper/WindowDimensions";
import { useEffect, useState } from "react";
import LoadingSnackbar from "../snackbars/LoadingSnackbar";
import { PlotDescriptionNewlineSeparator } from "helper/Constants";
import { decodeBase64 } from "helper/decodeBase64";
import { usePresentationMode } from 'helper/PresentationModeContext';
import { usePlotPreview } from 'helper/useAPIs';
import { Typography } from "@mui/material";

const PreviewLoadingPlotComponent = (
    {
        pipeId,
        nodeId,
        usernameFromPath,
        reload,
        isOnFlowNode = false,
        setPlotDescriptions = () => { },
        onImageWidthCalculated = () => {},
        use80percentWidth
    }
) => {
    const { height, width } = useWindowDimensions();
    const { isPresentationMode } = usePresentationMode();
    const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });
    const [imageUrl, setImageUrl] = useState(null);

    const { data, isLoading, isRefetching, refetch } = usePlotPreview({
        pipeId,
        blockId: nodeId,
        username: usernameFromPath,
        sparsify: isOnFlowNode,
        height: isOnFlowNode ? 300 : Math.round(height * 0.55),
        width: isOnFlowNode ? 150 : (use80percentWidth ? width * (2.8/4) : width * (5/6)), // The width is 150 if the plot is for the react flow node,  total width * 5/6 if the plot is on dashboard without filter categories, etc.
        presentationMode: isPresentationMode,
        includeDescription: false
    });

    useEffect(() => {
        if (data) {
            const url = URL.createObjectURL(data.blob);
            setImageUrl(url);
            let description = data.description;
            const descriptions = description ? description.split(PlotDescriptionNewlineSeparator) : [];
            setPlotDescriptions(descriptions);
        }

        // Cleanup URL on unmount
        return () => {
            if (imageUrl) {
                URL.revokeObjectURL(imageUrl);
            }
        };
    }, [data]);

    useEffect(() => {
        refetch()
    }, [reload, isPresentationMode])

    return (
        <>
            <LoadingSnackbar loading={isLoading || isRefetching} />
            {imageUrl && <img 
                src={imageUrl} 
                alt="" 
                draggable="false" 
                style={{
                    width: imgDimensions.width,
                    height: imgDimensions.height,
                    transform: 'translateZ(0)',
                    transformOrigin: "top left",
                    objectFit: "contain",
                    display: "block"
                }} 
                onLoad={(img) => {
                    setImgDimensions({ 
                        width: img.target.naturalWidth, 
                        height: img.target.naturalHeight 
                    });
                    onImageWidthCalculated(img.target.naturalWidth);
                }} 
            />}
        </>
    );
};

export default PreviewLoadingPlotComponent;