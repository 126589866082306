import { AdsClick, Link, LinkOff, OpenInNew, AdminPanelSettings } from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { PlotBlocks, SkippableBlocks } from "helper/Constants";
import { encodePipeId } from "helper/UrlUtils";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiContext } from "../../helper/ApiContext";
import Logger from "../../helper/Logger";
import { UserContext } from "../../helper/UserContext";
import { usePresentationMode } from "helper/PresentationModeContext";
import useWindowDimensions from "helper/WindowDimensions";

const EditBlockDetailsDialog = ({
    open,
    handleCancel,
    onSaveClick,
    title,
    setTitle,
    description,
    setDescription
}) => {
    return (
        <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>Edit block details</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Change the block details, then click save.
                </DialogContentText>
                <TextField autoFocus margin="dense" id="title" label="Title" fullWidth value={title}
                    onChange={(event) => {
                        setTitle(event.target.value);
                    }}
                />
                <TextField margin="dense" id="description" label="Description" fullWidth
                    multiline rows={8} value={description} onChange={(event) => { setDescription(event.target.value) }} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button variant="contained" onClick={onSaveClick}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}

const BlockDetailsComponent = ({
    block,
    pipeId,
    usernameFromPath,
    isForeignPipe = false,
    isOnDashboard = true,
    showStaticPlot = true,
    onSelectionChanged = () => { },
    onShowInteractivePlotClicked = () => { },
    isFilterCategoriesSideBlock = false,
}) => {
    const { api } = useContext(ApiContext);
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const [title, setTitle] = useState(block.title ?? "");
    const [description, setDescription] = useState(block.description ?? "");
    const [open, setOpen] = useState(false);
    const [blockTitle, setBlockTitle] = useState(block.title ?? "");
    const [blockDescription, setBlockDescription] = useState(block.description ?? "");
    const isSkippableBlock = SkippableBlocks.includes(block.blueprint.type);
    const [skipped, setSkipped] = useState(block.skip)
    const isSynced = block.configuration.elements.some(element => element.is_synced);
    const [synced, setSynced] = useState(isSynced);
    const hasSyncOption = block.configuration.elements.some(
        (element) => (element.name === "Y Columns" || element.name === "Y Axis" || element.name === "Columns")
            && element.is_synced != undefined
    );

    const nodeId = block?.flow_id
    const shouldShowOpenDetailsButton = isOnDashboard && !isForeignPipe()
    const shouldShowMakeInteractiveButton = isOnDashboard && PlotBlocks.includes(block.blueprint.type) && showStaticPlot

    const { height, width } = useWindowDimensions();
    const { isPresentationMode } = usePresentationMode();

    useEffect(() => {
        setBlockTitle(block.title ?? "")
        setBlockDescription(block.description ?? "")
    }, [block])

    const handleSavePlotAsPNG = () => {
        api.getPlotPreview({
            pipe_id: pipeId,
            block_id: nodeId,
            username: usernameFromPath,
            sparsify: false,
            height: Math.round(height * 0.55), 
            width: Math.round(height * 0.75),
            signal: null,
            presentationMode: isPresentationMode,
            includeDescription: true,
            format: "png"
        }).then(response => {
            const blob = new Blob([response.data], { type: response.headers['content-type'] });

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${blockTitle || 'plot'}.png`;

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }).catch(error => {
            console.error('Failed to download plot:', error);
        });
    };

    const handleSyncToggle = () => {
        const newSyncState = !synced;
        setSynced(newSyncState);

        const updatedConfig = block.configuration.elements.map((element) => {
            if (element.name === "Y Columns" || element.name === "Y Axis" || element.name === "Columns") {
                return { ...element, is_synced: newSyncState };
            }
            return element;
        });
        api.updateNodeConfiguration(pipeId, block.flow_id, usernameFromPath, { elements: updatedConfig })
            .then(response => {
                // updated the sync state, so we need to reload the page
                onSelectionChanged()
            })
            .catch(error => {
                console.error("Failed to update sync state: " + error);
                setSynced(!newSyncState);
            });
    };

    const onSaveClick = (event) => {
        event.preventDefault();
        api.updateNodeDetails(pipeId, nodeId, user.username, title, description).then((response) => {
            let block = response.data
            setBlockTitle(block.title)
            setBlockDescription(block.description)
            setOpen(false);
        }).catch((error) => {
            Logger.error("Error updating the block: " + JSON.stringify(error))
        })
    }

    const handleCancel = () => {
        setOpen(false);
        setTitle(blockTitle ?? "")
        setDescription(blockDescription ?? "")
    };

    const handleSkipToggle = (event) => {
        event.stopPropagation();
        api.updateNodeSkipFlag(pipeId, nodeId, user.username, !skipped).then((response) => {
            setSkipped(prevSkipped => !prevSkipped);
            onSelectionChanged() // updated the skip flag, so we need to reload the page
        }).catch((error) => {
            Logger.error("Error updating the block: " + JSON.stringify(error))
        })
    }

    const handleOpenDetailsClick = () => {
        navigate(`/pipe/${encodePipeId(usernameFromPath, pipeId)}/block/${block.flow_id}`);
    }

    const handleMakeInteractiveClick = () => {
        onShowInteractivePlotClicked();
    }

    return (
        <>
            <Stack spacing={1} sx={{ width: '100%' }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: isFilterCategoriesSideBlock ? 'column' : 'row',
                    }}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ width: '100%' }}
                    >
                        <Typography variant="h5">{blockTitle}</Typography>
                        {!isFilterCategoriesSideBlock && (
                            <Chip
                                sx={{ fontSize: '0.7rem', boxShadow: 1 }}
                                label={block.blueprint.title}
                                size="small"
                            />
                        )}
                    </Stack>
    
                    <Stack direction="row" spacing={1}
                        sx={{
                            flexWrap: isFilterCategoriesSideBlock ? 'wrap' : 'nowrap',
                            width: isFilterCategoriesSideBlock ? '100%' : 'auto',
                            justifyContent: isFilterCategoriesSideBlock ? 'flex-start' : 'flex-end',
                        }}
                    >
                        {shouldShowOpenDetailsButton && (
                            <Tooltip title="Open block details">
                                <IconButton
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        handleOpenDetailsClick()
                                    }}
                                >
                                    <OpenInNew />
                                </IconButton>
                            </Tooltip>
                        )}
                        {!isForeignPipe() && (
                            <>
                                {shouldShowMakeInteractiveButton && (
                                    <Tooltip title="Make plot interactive">
                                        <IconButton
                                            onClick={(event) => {
                                                event.stopPropagation()
                                                handleMakeInteractiveClick()
                                            }}
                                        >
                                            <AdsClick />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {isSkippableBlock && !isFilterCategoriesSideBlock && (
                                    <Tooltip title={skipped ? "Activate block" : "Skip block"}>
                                        <IconButton onClick={(event) => {
                                                handleSkipToggle(event);
                                            }}>
                                            <SkipNextIcon color={skipped ? "disabled" : "inherit"} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {isOnDashboard && hasSyncOption && (
                                    <Tooltip title={synced ? "Unsync axes" : "Sync axes"}>
                                        <IconButton
                                            onClick={handleSyncToggle}
                                            sx={{
                                                color: synced ? 'primary.main' : 'inherit',
                                            }}
                                        >
                                            {synced ? <Link /> : <LinkOff />}
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {PlotBlocks.includes(block.blueprint.type) &&  (
                                    <Tooltip title="Download Plot as Image">
                                        <IconButton onClick={(event) => {
                                                event.stopPropagation();
                                                handleSavePlotAsPNG();
                                            }}>
                                            <FileDownloadIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <Tooltip title="Edit block details">
                                    <IconButton onClick={(event) => {
                                            event.stopPropagation();
                                            setOpen(true);
                                        }}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                <div id={`block-${nodeId}-details-button-portal`} />
                            </>
                        )}
                    </Stack>
                </Box>
                <Typography
                    sx={{ whiteSpace: 'break-spaces' }} 
                    variant="body"
                >
                    {blockDescription}
                </Typography>
            </Stack>
    
            <EditBlockDetailsDialog
                open={open}
                handleCancel={handleCancel}
                onSaveClick={onSaveClick}
                title={title}
                setTitle={setTitle}
                description={description}
                setDescription={setDescription}
            />
        </>
    )
}

export default BlockDetailsComponent
