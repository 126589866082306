import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useRunPipe } from "../../helper/useAPIs";
import ErrorSnackbar from "../snackbars/ErrorSnackbar";
import DataTableComponent from "./DataTableComponent";

const DataLoadingTableComponent = React.memo(({ 
  pipeId, 
  nodeId, 
  username, 
  block,
  reloadPipeCounter,
  preview = false, 
  config = undefined, 
  isShowTableBlock = false 
}) => {
    const [tableParams, setTableParams] = useState({
        pagination: {
            pageIndex: 0,
            pageSize: 10
        },
        filters: {
            columnFilters: [],
            globalFilter: ''
        },
        sorting: []
    })

    const { 
        data = [], 
        isLoading,
        error,
        refetch
    } = useRunPipe({
        pipeId,
        nodeId,
        username,
        preview,
        pagination: tableParams.pagination,
        filters: tableParams.filters,
        sorting: tableParams.sorting
    });

    // Refetch when reloadPipeCounter changes
    useEffect(() => {
        if (reloadPipeCounter) {
            console.log("Refetching table data")
            refetch()
        }
    }, [reloadPipeCounter, refetch])

    const dataShape = useMemo(() => {
        const breakouts = data.length
        if (breakouts === 0) return [0, 0, 0]
        
        const firstBreakout = data[0]
        // Use meta length if available, otherwise fall back to df length
        const rows = firstBreakout.meta?.length ?? firstBreakout.df.length
        if (firstBreakout.df.length === 0) return [breakouts, 0, 0]
        
        const columns = Object.keys(firstBreakout.df[0]).length
        return [breakouts, rows, columns]
    }, [data])

    const reloadData = (columnFilters, globalFilter, pageIndex, pageSize, sorting) => {
        setTableParams({
            pagination: {
                pageIndex,
                pageSize
            },
            filters: {
                columnFilters,
                globalFilter
            },
            sorting
        })
    }

    const errorMessage = error ? (
        error.status === 404
            ? "Selected file could not be found on the server. Please select another file or reupload."
            : error.message
    ) : null

    return (
        <Stack direction="column" sx={{ overflow: "scroll" }}>
            {errorMessage && <ErrorSnackbar error={errorMessage} />}
            <Stack direction="row" spacing={2}>
                {data.map((breakout, index) => (
                    <Box key={index} sx={{ width: "100%" }}>
                        <Typography variant="h6" align="center">
                            {breakout.meta.breakout}
                        </Typography>
                        <DataTableComponent
                            data={breakout.df}
                            pipeId={pipeId}
                            nodeId={nodeId}
                            username={username}
                            block={block}
                            config={config}
                            reloadData={reloadData}
                            rowCount={breakout.meta.length}
                            isLoading={isLoading}
                            checkboxSelection={true}
                        />
                    </Box>
                ))}
            </Stack>
            <Typography variant="body">
                {dataShape.join(" x ")}
            </Typography>
        </Stack>
    )
})

export default DataLoadingTableComponent