import { Box, IconButton, Tooltip, Button, MenuItem, ListItemIcon } from '@mui/material';
import { Delete, Edit, Visibility, Search, Add } from "@mui/icons-material";
import { useState } from 'react';
import { MaterialReactTable } from 'material-react-table';

const PipesTableComponent = ({ 
    pipes, 
    onClick, 
    onEditClick, 
    onDeleteClick, 
    onVisibilityClick,
    showEditOption,
    showVisibilityOption,
    showDeleteOption,
    showCostCentersColumn = false,
    showCreateButton = false,
    onCreateClick
}) => {
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);

    const columns = [
        {
            accessorKey: 'name',
            header: 'Name',
            size: 200,
        },
        {
            accessorKey: 'data_source',
            header: 'Data Source',
            size: 200,
        }
    ].concat(showCostCentersColumn ? [
        {
            accessorKey: 'cost_centers',
            header: 'Cost Centers',
            size: 150,
            Cell: ({ row }) => (
                <Box>
                    {row.original.cost_centers || '-'}
                </Box>
            ),
        },
        {
            accessorKey: 'tag',
            header: 'Tag',
            size: 100,
        },
        {
            accessorKey: 'owner',
            header: 'Owner',
            size: 150,
        }
    ] : [])

    const TopToolbar = ({ table }) => (
        <Box>
            <Box sx={{ display: 'flex', gap: '0rem' }}>
                {table.getHeaderGroups()[0].headers.map(header => 
                    header.column.getCanFilter() && header.column.getFilterValue()
                )}
            </Box>
            {showCreateButton && (
                <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={(e) => {
                        e.stopPropagation();
                        onCreateClick();
                    }}
                >
                    Create New Pipe
                </Button>
            )}
        </Box>
    );

    const generateRowActionItems = ({ closeMenu, row }) => {
        return [].concat(showEditOption(row.original) ? [
            <MenuItem key={0} onClick={() => {
                closeMenu()
                onEditClick(row.original)
            }}>
                <ListItemIcon>
                    <Edit fontSize="small" />
                </ListItemIcon>
                Configure
            </MenuItem>
        ] : []).concat(showVisibilityOption(row.original) ? [
            <MenuItem key={1} onClick={() => {
                closeMenu()
                onVisibilityClick(row.original)
            }}>
                <ListItemIcon>
                    <Visibility fontSize="small" />
                </ListItemIcon>
                Change visibility
            </MenuItem>
        ] : []).concat(showDeleteOption(row.original) ? [
            <MenuItem key={2} onClick={() => {
                closeMenu()
                onDeleteClick(row.original)
            }}>
                <ListItemIcon>
                    <Delete fontSize="small" />
                </ListItemIcon>
                Delete
            </MenuItem>
        ] : [])
    }

    return (
        <Box sx={{ 
            width: '100%',
            position: 'relative',
            '& .MuiPaper-root': {
                width: '100%',
                maxWidth: 'none',
                boxShadow: 'none'
            },
            '& .MuiTable-root': {
                width: '100%'
            },
            '& .MuiTableContainer-root': {
                width: '100%'
            }
        }}>
            <MaterialReactTable
                columns={columns}
                data={pipes}
                renderTopToolbarCustomActions={({ table }) => <TopToolbar table={table} />}
                enableStickyHeader
                enableColumnResizing
                enableFullScreenToggle={false}
                enableColumnPinning={false}
                enableColumnActions={true}
                enableDensityToggle={false}
                layoutMode="grid"
                enableRowActions={true}
                renderRowActionMenuItems={generateRowActionItems}
                positionActionsColumn='last'
                muiTableContainerProps={{ 
                    sx: { 
                        maxHeight: 'calc(100vh - 300px)',
                        width: '100%',
                    }
                }}
                muiTablePaperProps={{
                    sx: { 
                        width: '100%',
                        maxWidth: 'none',
                    }
                }}
                muiTopToolbarProps={{
                    sx: {
                        width: '100%',
                        maxWidth: 'none'
                    }
                }}
                muiBottomToolbarProps={{
                    sx: {
                        width: '100%',
                        maxWidth: 'none'
                    }
                }}
                muiSearchTextFieldProps={{
                    placeholder: 'Search by name or tag...',
                    variant: 'outlined',
                    sx: { mb: 2 }
                }}
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onSortingChange={setSorting}
                state={{
                    columnFilters,
                    globalFilter,
                    sorting,
                }}
                muiTableBodyRowProps={({ row }) => ({
                    onClick: () => onClick(row.original),
                    sx: { cursor: 'pointer' }
                })}
                initialState={{
                    density: 'compact',
                    sorting: [{ id: 'name', desc: false }]
                }}
            />
        </Box>
    );
};

export default PipesTableComponent; 