import ClonePipeDialogComponent from "components/dialogs/ClonePipeDialogComponent";
import { encodePipeId, useIsForeignPipe, useURLParts } from "helper/UrlUtils";
import { toPng } from "html-to-image";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Background, Controls, ReactFlow, ReactFlowProvider, useEdgesState, useNodesState } from "@xyflow/react";
import '@xyflow/react/dist/style.css';
import { useNavigate } from "react-router-dom";
import Logger from "../../helper/Logger";
import DefaultPaper from "../DefaultPaper";
import CustomFlowEdge from "./edges/CustomFlowEdge";
import CustomFlowNode from "./nodes/CustomFlowNode";
import usePrevious from "../../helper/usePrevious";
import ChangeBranchOrderDialog from "./ChangeBranchOrderDialog";
import { usePresentationMode } from "helper/PresentationModeContext";
import { 
    usePipe, 
    useUpdateBlockOrder, 
    useAddNodeToPipe, 
    useDeleteNodeFromPipe,
    useAddEdgeToPipe,
    useDeleteEdgeFromPipe,
    useMoveNodeInPipe,
    useUpdateNodeInPipe,
    useUpdateNodeInDashboard,
    useUpdateNodeSkipFlag,
    useBranchMap,
    useUploadScreenshot,
} from 'helper/useAPIs';
import { Box, CircularProgress, Stack } from "@mui/material";
import useWindowDimensions from "helper/WindowDimensions";
import useFeatureFlags from "helper/useFeatureFlags";
import BlockLibrarySidebarComponent from "./sidebar/BlockLibrarySidebarComponent";
import DataTransferKey from "helper/DataTransferKey";

const PipelineGraphComponent = ({}) => {
    const branchMapRef = useRef({});
    const edgesRef = useRef({});
    const reactFlowWrapper = useRef(null);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const { pipeId, username: usernameFromPath, isTemplate } = useURLParts()
    const { height, width } = useWindowDimensions();
    const navigate = useNavigate();
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const isForeignPipe = useIsForeignPipe(usernameFromPath);
    const [dialogOpen, setDialogOpen] = useState(false)
    const pipeIdRef = useRef()
    pipeIdRef.current = pipeId
    const [branchMap, setBranchMap] = useState({});
    const [selectedBranchNumber, setSelectedBranchNumber] = useState(null);
    const [changeOrderDialogOpen, setChangeOrderDialogOpen] = useState(false);
    const [branchOrder, setBranchOrder] = useState({});
    const previousNodeCount = usePrevious(nodes.length);
    const previousEdgeCount = usePrevious(edges.length);
    const [skipFlag, setSkipFlag] = useState(false);
    const { isPresentationMode } = usePresentationMode();

    const { data: pipeData, isLoading: isPipeLoading } = usePipe(pipeId, usernameFromPath, isTemplate);
    const { data: branchMapData, isLoading: isBranchMapLoading } = useBranchMap(pipeId, usernameFromPath, isTemplate);
    const updateBlockOrderMutation = useUpdateBlockOrder();
    const addNodeMutation = useAddNodeToPipe({
        onError: (error) => {
            Logger.error('Failed to add node:', error);
        }
    });
    const deleteNodeMutation = useDeleteNodeFromPipe({
        onError: (error) => {
            Logger.error('Failed to delete node:', error);
        }
    });
    const addEdgeMutation = useAddEdgeToPipe();
    const deleteEdgeMutation = useDeleteEdgeFromPipe();
    const moveNodeMutation = useMoveNodeInPipe();
    const updateNodeMutation = useUpdateNodeInPipe();
    const updateNodeInDashboardMutation = useUpdateNodeInDashboard();
    const updateNodeSkipFlagMutation = useUpdateNodeSkipFlag();
    const uploadScreenshotMutation = useUploadScreenshot();

    const { currentUserIsAdmin } = useFeatureFlags()
    
    const isReadOnly = !currentUserIsAdmin || isTemplate;

    const handleBranchOrderChange = (newBranchOrder) => {
        setBranchOrder(newBranchOrder);
    };

    const handleSubmitOrderChanges = () => {
        let updatedBranchMap = { ...branchMap };
        Object.entries(branchOrder).forEach(([branchOrderNumber, newOrder]) => {
            Object.entries(branchMap).forEach(([nodeId, branchMapNumber]) => {
                if (branchMapNumber === parseInt(branchOrderNumber)) {
                    updatedBranchMap[nodeId] = parseInt(newOrder);
                }
            });
        });

        branchMapRef.current = updatedBranchMap;
        setBranchMap(updatedBranchMap);

        const blocks = Object.keys(updatedBranchMap).map(nodeId => ({
            id: nodeId,
            order: updatedBranchMap[nodeId]
        }));
        
        updateBlockOrderMutation.mutate({ 
            pipeId, 
            blocks, 
            username: usernameFromPath
        });

        handleCloseChangeOrderDialog();
    };


    const calculateBranchNumbers = (nodes, edges, previousBranchMap = {}) => {
        let branchMap = { ...previousBranchMap };
        let visitedNodes = new Set();
        let currentBranch = 0;
        edgesRef.current = edges
        const filteredValues = Object.values(branchMapRef.current).filter(val => val !== 0);
        const allValuesSame = filteredValues.every(val => val === filteredValues[0]);
        const getOutgoingEdges = (nodeId) => {
            return edges.filter(edge => edge.source === nodeId);
        };

        const getIncomingEdges = (nodeId) => {
            return edges.filter(edge => edge.target === nodeId);
        };
        const fileImportNode = nodes.find(node => node?.data?.blueprint?.type === "import");
        const fileImportEdges = fileImportNode ? edges.filter(edge => edge.source === fileImportNode.id) : [];

        const traverseInSeries = (startNodeId, branchNumber) => {
            let queue = [startNodeId];

            while (queue.length > 0) {
                const currentNode = queue.shift();
                if (visitedNodes.has(currentNode)) continue;
                visitedNodes.add(currentNode);
                if ((edges.length > 2 || skipFlag) && !allValuesSame && (fileImportEdges.length > 1)) {
                    if (skipFlag) {
                        setSkipFlag(false);
                    }
                    if (branchMapRef.current && branchMapRef.current[currentNode]) {
                        branchMap[currentNode] = branchMapRef.current[currentNode];
                        if (branchMap[currentNode] !== branchNumber) {
                            setBranchOrder(prevState => ({
                                ...prevState,
                                [branchNumber]: branchMapRef.current[currentNode]
                            }));
                        }
                    } else {
                        const customBranchNumber = parseInt(branchOrder[branchNumber]) || branchNumber;
                        branchMap[currentNode] = customBranchNumber;
                    }
                } else {
                    const customBranchNumber = parseInt(branchOrder[branchNumber]) || branchNumber;
                    branchMap[currentNode] = customBranchNumber;
                }

                const outgoingEdges = getOutgoingEdges(currentNode);

                if (outgoingEdges.length > 1) {
                    outgoingEdges.forEach((edge) => {
                        currentBranch++;
                        traverseInSeries(edge.target, currentBranch);
                    });
                } else if (outgoingEdges.length === 1) {
                    queue.push(outgoingEdges[0].target);
                }
            }
        };

        if (fileImportNode) {
            currentBranch++;
            traverseInSeries(fileImportNode.id, currentBranch);
        }

        nodes.forEach(node => {
            if (!visitedNodes.has(node.id)) {
                const outgoingEdges = getOutgoingEdges(node.id);
                const incomingEdges = getIncomingEdges(node.id);

                if (outgoingEdges.length === 0 && incomingEdges.length === 0) {
                    branchMap[node.id] = 0;
                } else {
                    currentBranch++;
                    traverseInSeries(node.id, currentBranch);
                }
            }
        });
        Object.keys(branchOrder).forEach(branchNumber => {
            if (!Object.values(branchMap).includes(parseInt(branchNumber))) {
                delete branchOrder[branchNumber];
            }
        });

        branchMapRef.current = branchMap;
        setBranchMap(branchMap);
    };

    const branchNameFromId = (branchNumber) => {
        let nodesInBranch = nodes.filter(node => branchMapRef.current[node.id] == branchNumber)
        return nodesInBranch.map(node => node.data.title).join(", ")
    }

    const handleOpenChangeOrderDialog = (nodeId) => {
        const branchNumber = branchMapRef.current[nodeId];

        if (branchNumber) {
            const currentBranchOrder = Object.keys(branchMapRef.current).reduce((order, nodeId) => {
                const branch = branchMapRef.current[nodeId];
                order[branch] = branchOrder[branch] || branch;
                return order;
            }, {});

            setBranchOrder(currentBranchOrder);
            setSelectedBranchNumber(branchNumber);
            setChangeOrderDialogOpen(true);
        } else {
            console.error("BranchMap is not populated or nodeId is invalid");
        }
    };

    const handleCloseChangeOrderDialog = () => {
        setChangeOrderDialogOpen(false);
        setSelectedBranchNumber(null);
    };

    const handleReadonlyError = () => {
        setDialogOpen(true)
    }

    const loadRemoteNodes = (nodes) => {
        let patchedNodes = nodes.map((node) => {
            const branchNumber = branchMapRef.current[node.flow_id];
            return {
                id: `${node.flow_id}`,
                data: {
                    ...node,
                    branchNumber: branchNumber || 0
                },
                position: node.position,
                type: "custom"
            };
        });
        setNodes(patchedNodes);  // Ensure this updates the node state with the branch number
    };

    const createEdgeDict = (base) => {
        return {
            id: `e${base.source}-${base.sourceHandle}-${base.target}-${base.targetHandle}`,
            type: 'custom',
            animated: 'true',
            style: { stroke: 'black' },
            ...base
        }
    }

    const loadRemoteEdges = (edges) => {
        let patchedEdges = edges.map((edge) => createEdgeDict({
            source: edge.source_flow_id.toString(),
            sourceHandle: edge.source_handle_id?.toString(),
            target: edge.target_flow_id.toString(),
            targetHandle: edge.target_handle_id?.toString()
        })
        )
        edgesRef.current = patchedEdges
        setEdges(patchedEdges)
    }

    const isValidConnection = (connection) => {
        let edges = edgesRef.current
        let otherEdgesOfTargetNode = edges.filter((edge) => (edge.target === connection.target && edge.targetHandle === connection.targetHandle))
        return otherEdgesOfTargetNode.length === 0
    }

    const onConnect = useCallback((params) => {
        if (isForeignPipe()) {
            handleReadonlyError()
            return
        }

        const sourceBranchNumber = branchMapRef.current[params.source];
        const targetNodeId = params.target;
        const updateBranchForConnectedNodes = (nodeId, newBranchNumber) => {
            branchMapRef.current[nodeId] = newBranchNumber;
            const outgoingEdges = edgesRef.current.filter(edge => edge.source === nodeId);
            outgoingEdges.forEach(edge => {
                updateBranchForConnectedNodes(edge.target, newBranchNumber);
            });
        };
        if (sourceBranchNumber !== undefined && sourceBranchNumber !== 1) {
            updateBranchForConnectedNodes(targetNodeId, sourceBranchNumber);
        }

        let edgeDict = createEdgeDict(params)
        addEdgeMutation.mutate({ 
            pipeId: pipeIdRef.current, 
            edge: edgeDict, 
            username: usernameFromPath
        });
    }, [usernameFromPath])

    const onDragOver = useCallback((event) => {
        event.preventDefault()
        event.dataTransfer.dropEffect = 'move'
    }, [])

    const uploadScreenshot = () => {
        toPng(document.querySelector('.react-flow'), { pixelRatio: 0.5 })
            .then((data) => {
                uploadScreenshotMutation.mutate({ 
                    pipeId: pipeIdRef.current, 
                    screenshotFile: data, 
                    username: usernameFromPath
                });
            });
    }

    const newUniqueNodeId = () => {
        let newNodeId = nodes.length + 1
        while (nodes.find((node) => node.id === newNodeId.toString())) {
            newNodeId += 1
        }
        return newNodeId.toString()
    }

    const addNode = (event) => {
        event.preventDefault()
        if (isForeignPipe() || isReadOnly) {
            handleReadonlyError()
            return
        }

        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
        const dataStr = event.dataTransfer.getData(DataTransferKey.NODE_DATA)
        const blueprint = JSON.parse(dataStr)

        // check if the dropped element is valid
        if (typeof blueprint === 'undefined' || !blueprint) {
            Logger.error("Invalid blueprint")
            return {}
        }

        const position = reactFlowInstance.screenToFlowPosition({
            x: event.clientX - reactFlowBounds.left + 150,
            y: event.clientY - reactFlowBounds.top,
        })

        let node = {
            id: newUniqueNodeId(),
            type: "custom",
            position,
            width: 150,
            height: 80,
            data: {
                blueprint: blueprint,
                configuration: blueprint.default_configuration,
                data: {
                    value: {}
                },
            }
        }
        
        addNodeMutation.mutate({ 
            pipeId: pipeIdRef.current, 
            node, 
            username: usernameFromPath
        });
        // uploadScreenshot()
    }

    const onNodeClick = (event, node) => {
        if (document.getElementById("basic-menu") !== null) {
            // for some reason clicking away from the node context menu triggers the `onNodeClick` event
            // so we have to check if the menu is still there
            return
        }
        if (isTemplate) {
            // don't allow entering blocks in templates
            return
        }
        if (isReadOnly && !node.data.dashboard_configuration.view_in_dashboard) {
            // don't allow non-admins to go into blocks that are hidden from the dashboard
            return
        }
        // TODO: <finn> find a way to abort running requests on the backend that we will never see
        const pipeUrl = encodePipeId(usernameFromPath, pipeId)
        navigate(`/pipe/${pipeUrl}/block/${node.id}`)
    }

    const onNodeDragStop = (event, node) => {
        if (isForeignPipe()) {
            handleReadonlyError()
            return
        }
        if (node) {
            moveNodeMutation.mutate({ 
                pipeId: pipeIdRef.current,
                nodeId: node.id,
                position: node.position,
                username: usernameFromPath
            })
        }
    }

    const onDropdownValueChanged = (nodeId, selectedOptions) => {
        updateNodeMutation.mutate({ 
            pipeId: pipeIdRef.current, 
            nodeId,
            username: usernameFromPath,
            value: selectedOptions 
        });
    }

    const onDeleteNode = (nodeId) => {
        if (isForeignPipe()) {
            handleReadonlyError()
            return
        }
        setNodes((nodes) => nodes.filter((node) => node.id !== nodeId))
        setEdges((edges) => edges.filter((edge) => edge.source !== nodeId && edge.target !== nodeId))
        deleteNodeMutation.mutate({ 
            pipeId: pipeIdRef.current, 
            nodeId, 
            username: usernameFromPath
        });
        // uploadScreenshot()
    }

    const onChangeDashboardFlag = (nodeId, checked) => {
        updateNodeInDashboardMutation.mutate({ 
            pipeId: pipeIdRef.current, 
            nodeId: nodeId, 
            username: usernameFromPath, 
            viewInDashboard: checked
        });
    }

    const onChangeSkipFlag = (nodeId, checked) => {
        updateNodeSkipFlagMutation.mutate({ 
            pipeId: pipeIdRef.current, 
            nodeId, 
            username: usernameFromPath, 
            skip: checked 
        });
    }

    const onDeleteEdge = (edgeId) => {
        if (isForeignPipe()) {
            handleReadonlyError()
            return
        }
        setEdges((edges) => edges.filter((edge) => edge.id !== edgeId))
        deleteEdgeMutation.mutate({ 
            pipeId: pipeIdRef.current, 
            edgeId, 
            username: usernameFromPath
        });
        // uploadScreenshot()
    }

    const nodeTypes = useMemo(() => ({
        custom: (reactFlowProps) => <CustomFlowNode
            pipeId={pipeId}
            usernameFromPath={usernameFromPath}
            isReadOnly={isReadOnly}
            isValidConnection={isValidConnection}
            onValueChanged={(selectedOption) => {
                onDropdownValueChanged(reactFlowProps.id, selectedOption)
            }}
            onDeleteButtonClick={(nodeId) => {
                onDeleteNode(nodeId)
            }}
            onCheckboxClick={(id, name, checked) => {
                if (name === 'Skip') {
                    onChangeSkipFlag(id, checked)
                } else {
                    onChangeDashboardFlag(id, checked)
                }
            }}
            onOpenChangeOrder={handleOpenChangeOrderDialog}
            isPresentationMode={isPresentationMode}
            {...reactFlowProps}
            
        />,
    }), [usernameFromPath, isPresentationMode])

    const edgeTypes = useMemo(() => ({
        custom: (reactFlowProps) => <CustomFlowEdge
            onCloseButtonClick={(edgeId) => {
                onDeleteEdge(edgeId)
            }}
            {...reactFlowProps}
        />
    }), [usernameFromPath])

    useEffect(() => {
        if ((previousNodeCount !== nodes.length || previousEdgeCount !== edges.length) && nodes.length > 0 && edges.length > 0) {
            calculateBranchNumbers(nodes, edges);
            const blocks = Object.keys(branchMapRef.current).map(nodeId => ({
                id: nodeId,
                order: branchMapRef.current[nodeId]
            }));
            if (!isTemplate) {
                updateBlockOrderMutation.mutate({ 
                    pipeId, 
                    blocks, 
                    username: usernameFromPath
                });
            }
        }
    }, [nodes.length, edges.length]);

    useEffect(() => {
        if (pipeData) {
            const { blocks, connections } = pipeData;
            loadRemoteNodes(blocks);
            loadRemoteEdges(connections);
        }
    }, [pipeData]);

    useEffect(() => {
        if (branchMapData) {
            setBranchMap(branchMapData);
            branchMapRef.current = branchMapData;
            if (Object.keys(branchMapData).length === 3) {
                setSkipFlag(true);
            }
        }
    }, [branchMapData]);

    useEffect(() => {
        if (pipeData && isTemplate) {
            handleReadonlyError();
        }
    }, [pipeData, isTemplate]);

    return (
        <Stack direction="row" spacing={2} sx={{ mt: 0 }}>
            {!isReadOnly && (
                <BlockLibrarySidebarComponent height={height} />
            )}
            <ReactFlowProvider>
                <Box
                    ref={reactFlowWrapper}
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        justifyContent: isReadOnly ? 'center' : 'flex-start',
                        alignItems: isReadOnly ? 'center' : 'flex-start',
                        width: isReadOnly ? '100%' : 'auto',
                    }}
                >
                    <DefaultPaper 
                        additionalSx={{
                            height: height-120,
                            width: width - (isReadOnly ? 0 : 300),
                            padding: -10,
                            overflow: "hidden",
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        {(isPipeLoading || isBranchMapLoading) ? (
                            <CircularProgress sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%'
                            }} />
                        ) : (
                            <>
                                <ClonePipeDialogComponent
                                    open={dialogOpen}
                                    setOpen={setDialogOpen}
                                    usernameFromPath={usernameFromPath}
                                    pipeId={pipeId}
                                    isTemplate={isTemplate}
                                />
                                <ReactFlow
                                    nodes={nodes}
                                    edges={edges}
                                    nodeTypes={nodeTypes}
                                    edgeTypes={edgeTypes}
                                    onNodesChange={onNodesChange}
                                    onEdgesChange={onEdgesChange}
                                    onConnect={onConnect}
                                    nodesDraggable={!isReadOnly}
                                    nodesConnectable={!isReadOnly}
                                    elementsSelectable={!isReadOnly}
                                    onInit={setReactFlowInstance}
                                    onDrop={(event) => {
                                        addNode(event)
                                    }}
                                    onDragOver={onDragOver}
                                    onNodeClick={onNodeClick}
                                    onNodeDragStop={onNodeDragStop}
                                    fitView
                                >
                                    <Background
                                        color="#000000"
                                        style={{ backgroundColor: "#F5f5f5" }}
                                    />
                                    <Controls />
                                </ReactFlow>
                                <ChangeBranchOrderDialog
                                    open={changeOrderDialogOpen}
                                    onClose={handleCloseChangeOrderDialog}
                                    branchOrder={branchOrder}
                                    branchNameFromId={branchNameFromId}
                                    selectedBranchNumber={selectedBranchNumber}
                                    handleBranchOrderChange={handleBranchOrderChange}
                                    handleSubmitOrderChanges={handleSubmitOrderChanges}
                                />
                            </>
                        )}
                    </DefaultPaper>
                </Box>
            </ReactFlowProvider>
        </Stack>
    )
}

export default PipelineGraphComponent