import { Box, Dialog, Tabs, Tab } from "@mui/material";
import DeleteDialogComponent from "components/dialogs/DeleteDialogComponent";
import EditPipeDialogComponent from "components/dialogs/EditPipeDialogComponent";
import { encodePipeId, useURLParts } from "helper/UrlUtils";
import useFeatureFlags from "helper/useFeatureFlags";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logger from "../../helper/Logger";
import Route from "../../helper/Route";
import { ChangeCostCentersComponent } from "./UserManagementPageComponent";
import { useTemplates, usePipes, useCreatePipe, useDeletePipe, useDeleteTemplate, useUpdateTemplateTag, useUpdateTemplateCostCenters, useUpdateTemplateOrganization, useUserProfile } from '../../helper/useAPIs'
import PipesTableComponent from './PipesTableComponent';
import useTabStore from '../../stores/tabStore';

const PipesPageComponent = () => {
    const { data: user } = useUserProfile()
    const { username } = useURLParts()
    const navigate = useNavigate()
    const { currentUserIsAdmin, isKws } = useFeatureFlags()
    const activeTab = useTabStore((state) => state.activeTab);
    const setActiveTab = useTabStore((state) => state.setActiveTab);
    const [editPipeDialogOpen, setEditPipeDialogOpen] = useState(false)
    const [pipeToEdit, setPipeToEdit] = useState(null)
    const [pipeToVisibility, setPipeToVisibility] = useState(undefined)
    const [deletePipeDialogOpen, setDeletePipeDialogOpen] = useState(false)
    const [pipeToDelete, setPipeToDelete] = useState(null)

    const { data: templates = [] } = useTemplates()
    const { data: pipes = [] } = usePipes(username)
    const createPipeMutation = useCreatePipe()
    const deletePipeMutation = useDeletePipe()
    const deleteTemplateMutation = useDeleteTemplate()
    const updateTemplateTagMutation = useUpdateTemplateTag()
    const updateTemplateCostCentersMutation = useUpdateTemplateCostCenters()
    const updateTemplateOrganizationMutation = useUpdateTemplateOrganization()
    
    const showTemplatesSection = user?.username === username

    const handleCreateClick = async (template) => {
        if (template) {
            navigate(Route.Template + "/" + template.id)
        } else {
            try {
                const response = await createPipeMutation.mutateAsync({ 
                    name: "new pipe",
                    templateId: undefined
                })
                const pipeUrl = encodePipeId(username, response.pipe_id)
                if (currentUserIsAdmin) {
                    navigate(Route.Pipe + "/" + pipeUrl)
                } else {
                    navigate(Route.Dashboard + "/" + pipeUrl)
                }
            } catch (error) {
                Logger.error("Could not create new pipe: " + JSON.stringify(error))
            }
        }
    }

    const handlePipeClick = (pipe) => {
        const pipeUrl = encodePipeId(username, pipe.pipe_id)
        if (currentUserIsAdmin) {
            navigate(Route.Pipe + "/" + pipeUrl)
        } else {
            navigate(Route.Dashboard + "/" + pipeUrl)
        }
    }

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const onSaveEdit = async (pipe, tag) => {
        if (!pipe.is_template) {
            console.log("Trying to edit non-template pipe");
            return
        }

        try {
            await updateTemplateTagMutation.mutateAsync({ 
                templateId: pipe.id, 
                tag 
            })
        } catch (error) {
            Logger.error("Could not update template: " + JSON.stringify(error))
        }
    }

    const onDeleteConfirmed = async (pipe) => {
        try {
            if (pipe.is_template) {
                await deleteTemplateMutation.mutateAsync(pipe.id)
                Logger.info("Template deleted successfully")
            } else {
                await deletePipeMutation.mutateAsync({
                    username: user.username,
                    pipeId: pipe.pipe_id
                })
                Logger.info("Pipe deleted successfully")
            }
        } catch (error) {
            Logger.error(`Could not delete ${pipe.is_template ? 'template' : 'pipe'}: ${JSON.stringify(error)}`)
        }
    }

    const handleVisibilityClose = async (cost_centers, organization) => {
        setPipeToVisibility(undefined)
        try {
            await updateTemplateCostCentersMutation.mutateAsync({
                templateId: pipeToVisibility?.id,
                costCenters: cost_centers
            })
            await updateTemplateOrganizationMutation.mutateAsync({
                templateId: pipeToVisibility?.id,
                organization
            })
        } catch (error) {
            Logger.error("Could not update template visibility: " + JSON.stringify(error))
        }
    }

    const showEditOption = (pipe) => currentUserIsAdmin && pipe.is_template && user && pipe.user_id === user.id;
    const showVisibilityOption = (pipe) => currentUserIsAdmin && pipe.is_template && user && pipe.user_id === user.id;
    const showDeleteOption = (pipe) => !pipe.is_template || currentUserIsAdmin;

    return (
        <Box sx={{ 
            width: '100vw',
            maxWidth: '100vw',
            mt: 8,
            px: 4,
            boxSizing: 'border-box',
            position: 'relative',
            left: '50%',
            transform: 'translateX(-50%)',
            right: 0
        }}>
            {!isKws && (
                <Tabs 
                    value={activeTab} 
                    onChange={handleTabChange}
                    sx={{ 
                        borderBottom: 1, 
                        borderColor: 'divider',
                        mb: 3,
                        position: 'relative',
                        zIndex: 1,
                        width: '100%'
                    }}
                >
                    <Tab label="Recent Pipes" />
                    {showTemplatesSection && <Tab label="Templates" />}
                </Tabs>
            )}

            <Box sx={{ 
                width: '100%',
                overflowX: 'hidden'
            }}>
                {activeTab === 0 && (
                    <Box sx={{ width: '100%' }}>
                        <PipesTableComponent
                            pipes={pipes}
                            onClick={handlePipeClick}
                            onEditClick={(template) => {
                                setEditPipeDialogOpen(true);
                                setPipeToEdit(template);
                            }}
                            onVisibilityClick={(template) => {
                                setPipeToVisibility(template);
                            }}
                            onDeleteClick={(template) => {
                                setDeletePipeDialogOpen(true);
                                setPipeToDelete(template);
                            }}
                            showEditOption={showEditOption}
                            showVisibilityOption={showVisibilityOption}
                            showDeleteOption={showDeleteOption}
                            showCostCentersColumn={false}
                            showCreateButton={true}
                            onCreateClick={() => handleCreateClick()}
                        />
                    </Box>
                )}

                {activeTab === 1 && showTemplatesSection && (
                    <Box sx={{ width: '100%' }}>
                        <PipesTableComponent
                            pipes={templates}
                            onClick={(template) => handleCreateClick(template)}
                            onEditClick={(template) => {
                                setEditPipeDialogOpen(true);
                                setPipeToEdit(template);
                            }}
                            onVisibilityClick={(template) => {
                                setPipeToVisibility(template);
                            }}
                            onDeleteClick={(template) => {
                                setDeletePipeDialogOpen(true);
                                setPipeToDelete(template);
                            }}
                            showEditOption={showEditOption}
                            showVisibilityOption={showVisibilityOption}
                            showDeleteOption={showDeleteOption}
                            showCostCentersColumn={true}
                            showCreateButton={true}
                            onCreateClick={() => handleCreateClick()}
                        />
                    </Box>
                )}
                <EditPipeDialogComponent
                    open={editPipeDialogOpen}
                    pipe={pipeToEdit}
                    onClose={() => setEditPipeDialogOpen(false)}
                    onSaveEdit={(tag) => {
                        onSaveEdit(pipeToEdit, tag)
                    }}
                />
                <Dialog
                    open={pipeToVisibility !== undefined}
                    onClose={() => setPipeToVisibility(undefined)}
                >
                    <ChangeCostCentersComponent
                        target={pipeToVisibility?.name}
                        originalCostCentersValue={pipeToVisibility?.cost_centers}
                        originalOrganizationId={pipeToVisibility?.organization_id}
                        onClose={handleVisibilityClose}
                    />
                </Dialog>
                <DeleteDialogComponent
                    open={deletePipeDialogOpen}
                    objectName={pipeToDelete?.name}
                    onClose={() => setDeletePipeDialogOpen(false)}
                    onDeleteConfirmed={() => {
                        onDeleteConfirmed(pipeToDelete)
                    }}
                />
            </Box>
        </Box>
    )
}

export default PipesPageComponent