import Logger from "../../helper/Logger";
import { Box, Grid, Stack, IconButton, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { PipeItemComponent } from "../PipeItemComponent";
import { useNavigate } from "react-router-dom";
import Route from "../../helper/Route";
import AppsIcon from '@mui/icons-material/Apps';
import DefaultPaper from "components/DefaultPaper";
import { encodePipeId } from "helper/UrlUtils";
import { usePipes, useUserProfile } from "../../helper/useAPIs";
import useFeatureFlags from "helper/useFeatureFlags";

const PipesWidgetComponent = () => {
    const { data: user } = useUserProfile();
    const navigate = useNavigate();
    const { currentUserIsAdmin } = useFeatureFlags();

    const { data: pipes = [], isError, error } = usePipes(user?.username);

    if (isError) {
        Logger.error("Could not get user pipes: " + JSON.stringify(error));
    }

    const handlePipeClick = (pipe) => {
        const pipeUrl = encodePipeId(user.username, pipe.pipe_id);
        if (currentUserIsAdmin) {
            navigate(Route.Pipe + "/" + pipeUrl);
        } else {
            navigate(Route.Dashboard + "/" + pipeUrl);
        }
    };

    const handleMainClick = () => {
        navigate(Route.Pipes + "/" + user.username);
    };

    return (
        <DefaultPaper additionalSx={{ flexDirection: "column", padding: '20px', margin: '20px', height: '100%' }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h5">My Pipes</Typography>
                <Tooltip title="Go to Pipes Page">
                    <IconButton onClick={handleMainClick}>
                        <AppsIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
            <Grid container spacing={3} sx={{ mt: 2 }}>
                {pipes.slice(0, 5).map((pipe, index) => (
                    <Grid item key={pipe.pipe_id || index}>
                        <Box>
                            <PipeItemComponent 
                                pipe={pipe} 
                                onClick={() => handlePipeClick(pipe)} 
                            />
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </DefaultPaper>
    );
};

export default PipesWidgetComponent;