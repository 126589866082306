import { FilterList, RestartAlt } from "@mui/icons-material";
import { Masonry } from "@mui/lab";
import { Box, Button, IconButton, Stack, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import CreateSubgroupNameComponent from "components/filter/CreateSubgroupNameComponent";
import FilterMinMaxComponent from "components/filter/FilterMinMaxComponent";
import DataLoadingTableComponent from "components/table/DataLoadingTableComponent";
import { useIsForeignPipe } from "helper/UrlUtils";
import useFeatureFlags from "helper/useFeatureFlags";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useFilterConfig, useUpdateFilterConfig } from "../../helper/useAPIs";
import usePrevious from "../../helper/usePrevious";
import { FilterListComponent } from "../filter/FilterListComponent";

const FilterPageComponent = ({ pipeId, nodeId, blockType, usernameFromPath, reload, showTable = true, onSelectionChanged = () => { }, isDashboardItem = false, isFilterCategoriesSideBlock = false }) => {
    const [columns, setColumns] = useState({})
    const [userInput, setUserInput] = useState({})
    const [disabledRows, setDisabledRows] = useState({})
    const previousUserInput = usePrevious(userInput)
    const [reloadTableCounter, reloadTable] = useState(0)
    const [subgroupTitle, setSubgroupTitle] = useState("")
    const isForeignPipe = useIsForeignPipe(usernameFromPath);
    const { hideCountColumn } = useFeatureFlags();
    const [valueChanged, setValueChanged] = useState(false)

    // Use React Query hooks
    const { data: filterConfig, isLoading: isLoadingFilterConfig } = useFilterConfig(pipeId, nodeId, usernameFromPath)
    const { mutate: updateFilterConfig, isPending: isUpdatingFilterConfig } = useUpdateFilterConfig()

    useEffect(() => {
        if (filterConfig) {
            let { filter_config, user_input, disabled_rows } = filterConfig

            if (hideCountColumn) {
                Object.keys(filter_config.categorical_deps).forEach((key) => {
                    filter_config.categorical_deps[key].values = filter_config.categorical_deps[key].values.map((v) => {
                        if (v.hasOwnProperty("count")) {
                            delete v.count
                        }
                        return v
                    })
                })
            }

            let columns = Object.assign(
                filter_config.numeric_deps, 
                filter_config.categorical_deps, 
                filter_config.ordinal_deps
            )
            ensureUserInputComplete(user_input, columns)
            setColumns(columns)
            setDisabledRows(disabled_rows)
        }
    }, [filterConfig])

    const areObjectsSame = (a, b) => {
        if (typeof a !== typeof b)
            return false
        if (typeof a === "undefined")
            return true
        if (typeof a === "string" || typeof b === "number")
            return a === b
        if (typeof a === "object") {
            if (Array.isArray(a)) {
                let aSorted = a.sort()
                let bSorted = b.sort()
                return aSorted.every((value, index) => areObjectsSame(value, bSorted[index]))
            } else {
                if (Object.keys(a).length !== Object.keys(b).length)
                    return false
                // Numeric columns might contain different out_min/out_max values, which we disregard in this context
                if (a["type"] && a["type"] === "n")
                    return a.min === b.min && a.max === b.max
                let equalItems = Object.keys(a).filter((key) => (areObjectsSame(a[key], b[key])))
                return equalItems.length === Object.keys(a).length
            }
        } else {
            console.log(`Unknown object type ${typeof a}`);
            return false
        }
    }

    useEffect(() => {
        if (userInput && Object.keys(userInput).length > 0 && columns && 
            Object.keys(columns).length === Object.keys(userInput).filter((key) => key !== "subgroup_title").length) {
            
            if (previousUserInput && areObjectsSame(previousUserInput, userInput)) {
                return
            }

            // Reduce filters to only changed ones
            let reducedUserInput = { ...userInput }
            Object.entries(userInput).forEach(([key, value]) => {
                let column = columns[key]
                if (key === "subgroup_title") {
                    // do nothing
                } else if (column.type === "o" || column.type === "c") {
                    if (value.length === column.values.length) {
                        delete reducedUserInput[key]
                    }
                } else if (column.type === "n") {
                    if (value.min === column.min && value.max === column.max) {
                        delete reducedUserInput[key]
                    }
                }
            })

            updateFilterConfig({
                pipeId,
                nodeId, 
                username: usernameFromPath,
                config: reducedUserInput
            }, {
                onSuccess: () => {
                    reloadTable(prev => prev + 1)
                }
            })
        }
    }, [userInput])

    const ensureUserInputComplete = (savedUserInput, filterConfig) => {
        let newUserInput = { ...savedUserInput }

        // Here we ensure we have a user input for all available keys, since we only store the diff for easier handling in the backend
        // if the input file changes we might even have invalid filters here which we first remove
        let requiredKeys = Object.keys(filterConfig)
        Object.keys(newUserInput).forEach((key) => {
            if (key === "subgroup_title") {
                setSubgroupTitle(newUserInput["subgroup_title"])
                return // keep in user input
            }
            if (!requiredKeys.includes(key)) {
                delete newUserInput[key] // might be from an older file input
            }
        })

        requiredKeys.forEach((key) => {
            if (!(key in newUserInput)) {
                let value = filterConfig[key]
                if (value.type === "n") {
                    newUserInput[key] = value
                } else {
                    newUserInput[key] = value.values.map((v) => (v[key]))
                }
            }
        })

        setUserInput(newUserInput)
    }

    const visibleColumns = () => {
        if (Object.keys(columns).length === 0) { return [] }
        // show columns with less entries first
        var columnEntries = Object.entries(columns)
        let categoricalEntries = columnEntries.filter(([key, value]) => value.type === "c")
        let numericEntries = columnEntries.filter(([key, value]) => value.type === "n")
        columnEntries = categoricalEntries.sort((a, b) => a[1].values.length - b[1].values.length).concat(numericEntries)
        return columnEntries
    }

    const handleFilterChange = (colName, selectedRows) => {
        if (columns[colName].type === "n") {
            return false
        }
        let newUserInput = structuredClone(userInput)
        newUserInput[colName] = selectedRows
        setUserInput(newUserInput)
        setValueChanged(true)
    }

    const isChecked = (col, rowValue) => {
        return userInput[col]?.indexOf(rowValue) !== -1
    }

    const allChecked = (colName) => {
        if (columns[colName]?.type === "n" || columns[colName]?.type === "o") {
            // TODO:
            return false;
        } else {
            return userInput[colName]?.length === columns[colName]?.values?.length
        }
    }

    const isIndeterminate = (colName) => {
        return userInput[colName]?.length > 0 && !allChecked(colName)
    }

    const minMaxFilterChanged = (colName, min, max) => {
        let newUserInput = structuredClone(userInput)
        newUserInput[colName].min = min
        newUserInput[colName].max = max
        // TODO: check if values are numerical (eg. no "46.")
        setUserInput(newUserInput)
    }

    const subgroupNameChanged = (name) => {
        let newUserInput = structuredClone(userInput)
        newUserInput["subgroup_title"] = name
        setUserInput(newUserInput)
    }

    const resetAllFilters = () => {
        let newUserInput = structuredClone(userInput)
        Object.keys(newUserInput).forEach((key) => {
            let column = columns[key]
            if (newUserInput[key].type === "n" && column.type === "n") {
                newUserInput[key] = { min: column.min, max: column.max }
            } else if (key !== "subgroup_title") {
                newUserInput[key] = column.values?.map((v) => (v[key]))
            }
        })
        setUserInput(newUserInput)
    }

    const masonryColumns = () => {
        var containsCountColumn = true
        let categoricalColumns = Object.values(columns).filter((c) => (c.type === 'c'))
        if (categoricalColumns.length > 0 && categoricalColumns[0].values.length > 0) {
            containsCountColumn = Object.keys(categoricalColumns[0].values[0]).length > 1
        }

        var columnsConfig = { onecol: 1 }
        if (containsCountColumn) {
            columnsConfig.twocol = 2
            columnsConfig.threecol = 3
            columnsConfig.fourcol = 4
            columnsConfig.fivecol = 5
            columnsConfig.sixcol = 6
        } else {
            columnsConfig.twocol_small = 2
            columnsConfig.threecol_small = 3
            columnsConfig.fourcol_small = 4
            columnsConfig.fivecol_small = 5
            columnsConfig.sixcol_small = 6
        }
        return columnsConfig
    }
    const renderFilterComponents = () => {
        return visibleColumns().map(([columnName, column], index) => {
            if (column.type === "c") {
                const filterIndex = Object.keys(userInput).indexOf(columnName)
                return (
                    <Box
                        key={index}
                    >
                        <FilterListComponent
                            title={columnName}
                            items={column.values}
                            filterIndex={filterIndex}
                            isChecked={(rowValue) => isChecked(columnName, rowValue[columnName])}
                            isForeignPipe={isForeignPipe}
                            handleFilterChange={(selectedRows) => handleFilterChange(columnName, selectedRows)}
                            disabledRows={disabledRows}
                            isLoading={isLoadingFilterConfig || isUpdatingFilterConfig}
                        />
                    </Box>
                )
            } else if (column.type === "n" && blockType !== "create_subgroup") {
                return (
                    <Box
                        key={index}
                    >
                        <FilterMinMaxComponent
                            columnName={columnName}
                            column={column}
                            userInput={userInput[columnName]}
                            filterChanged={(minValue, maxValue) => {
                                minMaxFilterChanged(columnName, minValue, maxValue)
                            }}
                            isForeignPipe={isForeignPipe}
                        />
                    </Box>
                )
            }
        })}

    return (
        <Box sx={isFilterCategoriesSideBlock ? { width: '14.7vw' } : {}}>
            {isFilterCategoriesSideBlock ? (
                <Stack direction="column" spacing={2}>
                    {renderFilterComponents()}
                </Stack>
            ) : (
                <Masonry columns={masonryColumns()}>{renderFilterComponents()}</Masonry>
            )}
            {document.getElementById(`block-${nodeId}-details-button-portal`) && blockType !== "create_subgroup" && createPortal(
                    <Stack direction={"row"} spacing={1}>
                        <Tooltip title="Reset all filters">
                            <IconButton
                                color="inherit"
                                onClick={(event) => {
                                    event.stopPropagation()
                                    resetAllFilters()
                                }}
                            >
                                <RestartAlt />
                            </IconButton>
                        </Tooltip>
                        {valueChanged && (
                            <Tooltip title="Apply filters & Reload page">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        onSelectionChanged()
                                    }}
                                    startIcon={<FilterList />}
                                >
                                    Apply
                                </Button>
                            </Tooltip>
                        )}
                    </Stack>,
                    document.getElementById(`block-${nodeId}-details-button-portal`)
                )}
    
            
            {blockType === "create_subgroup" && (
                <CreateSubgroupNameComponent
                    disabled={isForeignPipe()}
                    initialTitle={subgroupTitle}
                    onSaveButtonClick={subgroupNameChanged}
                />
            )}
            {showTable && (
                <Box sx={{ mt: 3 }}>
                    <DataLoadingTableComponent
                        pipeId={pipeId}
                        nodeId={nodeId}
                        username={usernameFromPath}
                        reloadPipeCounter={reloadTableCounter} // we have two reload counters because we need to reload the table, but not the pipe/filters when the values change
                    />
                </Box>
            )}
            
           
        </Box>
    )  
}

export default FilterPageComponent;