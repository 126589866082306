import { List, ListItem, Checkbox, Typography, ListItemIcon, ListItemText, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

const AdminModeOptionsList = ({
    config,
    onVisibilityChange
}) => {
    const handleSelectAll = (checked) => {
        if (config.options) {
            config.options.forEach(option => {
                const isCurrentlyHidden = config.hidden_options?.includes(option);
                if (isCurrentlyHidden === checked) {
                    onVisibilityChange(option);
                }
            });
        }
    };

    const areAllSelected = () => {
        if (!config.options || config.options.length === 0) return false;
        return !config.options.some(option => config.hidden_options?.includes(option));
    };

    return (
        <List dense>
            <ListItem dense sx={{ ml: -4, wordWrap: 'break-word', maxWidth: '100%', mt: -4, mb: -2, py: -2 }}>
                <ListItemIcon>
                    <Checkbox
                        checked={areAllSelected()}
                        indeterminate={config.options && 
                            config.options.length > 0 && 
                            !areAllSelected() && 
                            config.options.some(option => !config.hidden_options?.includes(option))}
                        onChange={(e) => handleSelectAll(e.target.checked)}
                    />
                </ListItemIcon>
                <ListItemText primary={<Typography variant="caption"><strong>Select All</strong></Typography>} />
            </ListItem>
            {config.options && config.options.map((option, index) => (
                <ListItem 
                    dense
                    key={index}
                    sx={{ ml: -4, wordWrap: 'break-word', width: '120%', my: -2, py: -2 }}
                >
                    <ListItemIcon>
                        <Checkbox
                            checked={!config.hidden_options?.includes(option)}
                            onChange={() => onVisibilityChange(option)}
                        />
                    </ListItemIcon>
                    <ListItemText primary={option} />
                </ListItem>
            ))}
        </List>
    );
};

export default AdminModeOptionsList;
