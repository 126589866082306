import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { ApiContext } from './ApiContext'
import { decodeBase64 } from './decodeBase64'

// Basic Hooks
export function usePing() {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['ping'],
    queryFn: async () => {
      const response = await api.ping()
      return response.data
    }
  })
}

// Auth Related Hooks
export function useSignup() {
  const { api } = useContext(ApiContext)
  return useMutation({
    mutationFn: async (params) => {
      const response = await api.signup(params)
      return response.data
    }
  })
}

export function useLogin() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()
  
  return useMutation({
    mutationKey: ['login'],
    mutationFn: async (params) => {
      const response = await api.login(params)
      return response
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userProfile'] })
      queryClient.invalidateQueries({ queryKey: ['pipes'] })
    }
  })
}

export function useResetPassword() {
  const { api } = useContext(ApiContext)
  return useMutation({
    mutationFn: async ({ user, newPassword }) => {
      const response = await api.resetPassword(user, newPassword)
      return response.data
    }
  })
}

// Organization Hooks
export function useCreateOrganization() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()
  
  return useMutation({
    mutationFn: async (orgName) => {
      const response = await api.createOrganization(orgName)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organizations'] })
    }
  })
}

export function useBlocks() {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['blocks'],
    queryFn: async () => {
      const response = await api.getBlocks()
      return response.data
    }
  })
}

export function useOrganizations() {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['organizations'],
    queryFn: async () => {
      const response = await api.getOrganizations()
      return response.data
    }
  })
}

export function useUpdateBlockOrder() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, blocks, username }) => {
      const response = await api.updateBlockOrder(pipeId, blocks, username)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ 
        queryKey: ['pipe', variables.pipeId] 
      })
    }
  })
}

// Template Hooks
export function useTemplates() {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['templates'],
    queryFn: async () => {
      const response = await api.getTemplates()
      return response.data
    },
    staleTime: 1000 * 60 * 5 // 5 minutes
  })
}

// TODO: This may not be needed, see usePipe
export function useTemplate(templateId) {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['template', templateId],
    queryFn: async () => {
      const response = await api.getTemplate(templateId)
      return response.data
    }
  })
}

export function useCreateTemplate() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, username }) => {
      const response = await api.createTemplate(pipeId, username)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['templates'] })
    }
  })
}

export function useUpdateTemplateTag() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ templateId, tag }) => {
      const response = await api.updateTemplateTag(templateId, tag)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ 
        queryKey: ['template', variables.templateId] 
      })
    }
  })
}

export function useDeleteTemplate() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (templateId) => {
      const response = await api.deleteTemplate(templateId)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['templates'] })
    }
  })
}

// User Related Hooks
export function useUserProfile() {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['userProfile'],
    queryFn: async () => {
      const response = await api.getUserProfile()
      return response.data
    },
    retry: 1,
    retryDelay: 1000
  })
}

// Pipe Related Hooks
export function usePipes(username) {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['pipes', username],
    queryFn: async () => {
      const response = await api.getUserPipes(username)
      return response.data
    },
    enabled: !!username
  })
}

export function usePipe(pipeId, username, isTemplate = false) {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['pipe', pipeId, username],
    queryFn: async () => {
      const response = isTemplate ? 
        await api.getTemplate(pipeId) :
        await api.getUserPipe(pipeId, username)
      return response.data
    },
    enabled: !!pipeId && (!!username || isTemplate)
  })
}

export function useBlock(pipeId, blockId, username) {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['block', pipeId, blockId, username],
    queryFn: async () => {
      const response = await api.getBlock(pipeId, blockId, username)
      return response.data
    },
    enabled: !isNaN(pipeId) && !isNaN(blockId) && !!username
  })
}

export function useBranchMap(pipeId, username, isTemplate = false) {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['branchMap', pipeId, username],
    queryFn: async () => {
      const response = await api.getBranchMap(pipeId, username)
      return response.data
    },
    enabled: !isTemplate && !!username
  })
}

export function useCreatePipe() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ name, templateId }) => {
      const response = await api.createNewPipe(name, templateId)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['pipes'] })
    }
  })
}

export function useClonePipe() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, owner }) => {
      const response = await api.clonePipe(pipeId, owner)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['pipes'] })
    }
  })
}

export function useDeletePipe() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ username, pipeId }) => {
      const response = await api.deletePipe(username, pipeId)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pipes', variables.username] })
    }
  })
}

// File Related Hooks
export function useUserFiles(username) {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['files', username],
    queryFn: async () => {
      const response = await api.getUserFiles(username)
      return response.data
    }
  })
}

export function useFile(filename) {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['file', filename],
    queryFn: async () => {
      const response = await api.getFile(filename)
      return response.data
    }
  })
}

export function useUpdateFileDTypeMapping() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ filename, mapping, sources }) => {
      const response = await api.updateFileDTypeMapping(filename, mapping, sources)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['file', variables.filename] })
    }
  })
}

export function useUpdateFileCostCenters() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ filename, costCenters }) => {
      const response = await api.updateFileCostCenters(filename, costCenters)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['file', variables.filename] })
    }
  })
}

export function useUpdateFileOrganization() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ filename, organization }) => {
      const response = await api.updateFileOrganization(filename, organization)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['file', variables.filename] })
    }
  })
}

// Filter Related Hooks
export function useFilterConfig(pipeId, nodeId, username) {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['filterConfig', pipeId, nodeId, username],
    queryFn: async () => {
      const response = await api.getFilterConfig(pipeId, nodeId, username)
      return response.data
    },
    enabled: !!pipeId && !!nodeId && !!username
  })
}

export function useUpdateFilterConfig() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, nodeId, username, config }) => {
      const response = await api.updateNodeFilterConfig(pipeId, nodeId, username, config)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ 
        queryKey: ['filterConfig', variables.pipeId, variables.nodeId, variables.username] 
      })
    }
  })
}

// Pipe Update Hooks
export function useUpdatePipe() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()
  
  return useMutation({
    mutationFn: async ({ pipeId, params, username }) => {
      const response = await api.updateUserPipe(pipeId, params, username)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pipe', variables.pipeId] })
    }
  })
}

export function useAddNodeToPipe() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, node, username }) => {
      const response = await api.addNodeToPipe(pipeId, node, username)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pipe', variables.pipeId] })
    }
  })
}

export function useUpdateNodeInPipe() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, nodeId, username, value }) => {
      const response = await api.updateNodeInPipe(pipeId, nodeId, username, value)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ 
        queryKey: ['pipe', variables.pipeId],
        queryKey: ['block', variables.pipeId, variables.nodeId]
      })
    }
  })
}

export function useUpdateNodeConfiguration() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, nodeId, username, configuration }) => {
      const response = await api.updateNodeConfiguration(pipeId, nodeId, username, configuration)
      return response.data
    },
    onSuccess: (data, variables) => {
      // Invalidate all affected queries
      queryClient.invalidateQueries({ 
        queryKey: ['block', variables.pipeId, variables.nodeId]
      })
      queryClient.invalidateQueries({ 
        queryKey: ['runPipe', variables.pipeId, variables.nodeId]
      })
      queryClient.invalidateQueries({ 
        queryKey: ['filterConfig', variables.pipeId, variables.nodeId]
      })
      queryClient.invalidateQueries({ 
        queryKey: ['spec', variables.pipeId, variables.nodeId]
      })
    }
  })
}

export function useUpdateNodeInDashboard() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, nodeId, username, viewInDashboard }) => {
      const response = await api.updateNodeInDashboard(pipeId, nodeId, username, viewInDashboard)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pipe', variables.pipeId] })
    }
  })
}

export function useUpdateNodeSkipFlag() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, nodeId, username, skip }) => {
      const response = await api.updateNodeSkipFlag(pipeId, nodeId, username, skip)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pipe', variables.pipeId] })
    }
  })
}

export function useUpdateNodeDetails() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, nodeId, username, title, description }) => {
      const response = await api.updateNodeDetails(pipeId, nodeId, username, title, description)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ 
        queryKey: ['block', variables.pipeId, variables.nodeId]
      })
    }
  })
}

export function useMoveNodeInPipe() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, nodeId, position, username }) => {
      const response = await api.moveNodeInPipe(pipeId, nodeId, position, username)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pipe', variables.pipeId] })
    }
  })
}

export function useDeleteNodeFromPipe() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, nodeId, username }) => {
      const response = await api.deleteNodeFromPipe(pipeId, nodeId, username)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pipe', variables.pipeId] })
    }
  })
}

export function useAddEdgeToPipe() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, edge, username }) => {
      const response = await api.addEdgeToPipe(pipeId, edge, username)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pipe', variables.pipeId] })
    }
  })
}

export function useDeleteEdgeFromPipe() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, edgeId, username }) => {
      const response = await api.deleteEdgeFromPipe(pipeId, edgeId, username)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pipe', variables.pipeId] })
    }
  })
}

// Pipe Snapshot Hooks
export function useSavePipeSnapshot() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, title, description, tags }) => {
      const response = await api.savePipeSnapshot(pipeId, title, description, tags)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pipe', variables.pipeId] })
    }
  })
}

// File Upload/Delete Hooks
export function useUploadFiles() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ files, overwrite, onUploadProgress }) => {
      const response = await api.uploadFiles(files, overwrite, onUploadProgress)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['files'] })
    }
  })
}

export function useDeleteFile() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (filename) => {
      const response = await api.deleteFile(filename)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['files'] })
    }
  })
}

// Run Pipe Query Hook
export function useRunPipe({ pipeId, nodeId, username, preview, pagination, filters, sorting }) {
  const { api } = useContext(ApiContext)
  
  return useQuery({
    queryKey: ['runPipe', pipeId, nodeId, username, pagination, filters, sorting],
    queryFn: async () => {
      const response = await api.runPipe(
        pipeId,
        nodeId,
        preview ? 10 : undefined,
        username,
        `${(pagination?.pageIndex ?? 0) * (pagination?.pageSize ?? 10)}`,
        `${pagination?.pageSize ?? 10}`,
        JSON.stringify(filters?.columnFilters ?? []),
        filters?.globalFilter ?? '',
        JSON.stringify(sorting ?? [])
      )
      
      return response.data.map((dict) => ({
        ...dict,
        df: JSON.parse(dict.df),
      }))
    },
    enabled: Boolean(pipeId && nodeId && username),
    gcTime: 30000, // Keep cache for 30 seconds
    staleTime: 30000, // Consider data stale after 30 seconds
    retry: (failureCount, error) => {
      return failureCount < 3 && error?.status !== 404
    },
    onError: (error) => {
      Logger.error("Could not get contents for file:", error)
    }
  })
}

// Plot Related Hooks
export function useSpec({ pipeId, blockId, username, height, width, presentationMode }) {
  const { api } = useContext(ApiContext);
  
  return useQuery({
    queryKey: ['spec', pipeId, blockId, username, presentationMode],
    queryFn: async () => {
      try {
        const response = await api.getSpec({
          pipe_id: pipeId,
          block_id: blockId,
          username,
          height,
          width,
          presentationMode
        });
        return response.data;
      } catch (error) {
        // Transform error to be more user-friendly
        if (error.response?.status === 404) {
          throw new Error('Specification not found');
        }
        if (error.response?.status === 403) {
          throw new Error('You do not have permission to view this specification');
        }
        throw new Error(error.response?.data?.message || 'Failed to load specification');
      }
    },
    enabled: !isNaN(pipeId) && !isNaN(blockId),
    retry: 2, // Retry failed requests twice
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000), // Exponential backoff
    onError: (error) => {
      Logger.error(`Error fetching spec for pipe ${pipeId}, block ${blockId}:`, error);
    }
  });
}

export function usePlotPreview({ pipeId, blockId, username, sparsify, height, width, signal, presentationMode, includeDescription, format }) {
  const { api } = useContext(ApiContext);
  
  return useQuery({
    queryKey: ['plotPreview', pipeId, blockId, username, sparsify, height, width, presentationMode, includeDescription, format],
    queryFn: async () => {
      const response = await api.getPlotPreview({
        pipe_id: pipeId,
        block_id: blockId,
        username: username,
        sparsify: sparsify,
        height: height,
        width: width,
        signal: signal,
        presentationMode: presentationMode,
        includeDescription: includeDescription,
        format: format
      });
      let description = decodeBase64(response.headers?.get("x-plot-description") || "");
      return {
        blob: response.data,
        description: description
      };
    },
    gcTime: 1000 * 60 * 5, // Keep cache for 5 minutes
    staleTime: 1000 * 60 * 2, // Consider data stale after 2 minutes
    enabled: !!(pipeId && blockId && username), // Only run when we have required params
  });
}

export function useUploadScreenshot() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, screenshotFile, username }) => {
      const response = await api.uploadScreenshot(pipeId, screenshotFile, username)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pipe', variables.pipeId] })
    }
  })
}

// Admin Related Hooks
export function useUsers() {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      const response = await api.getUsers()
      return response.data
    }
  })
}

export function useDeleteUser() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (user) => {
      const response = await api.deleteUser(user)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] })
    }
  })
}

export function useUpdateUserRole() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ user, role }) => {
      const response = await api.updateUserRole(user, role)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] })
    }
  })
}

export function useUpdateUserOrganization() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ user, organization }) => {
      const response = await api.updateUserOrganization(user, organization)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] })
    }
  })
}

export function useUpdateUserCostCenters() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ user, costCenters }) => {
      const response = await api.updateUserCostCenters(user, costCenters)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] })
    }
  })
}

export function useUpdateTemplateCostCenters() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ templateId, costCenters }) => {
      const response = await api.updateTemplateCostCenters(templateId, costCenters)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['template', variables.templateId] })
    }
  })
}

export function useUpdateTemplateOrganization() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ templateId, organization }) => {
      const response = await api.updateTemplateOrganization(templateId, organization)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['template', variables.templateId] })
    }
  })
}

// Pipe Operations
export function useRenamePipe() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, name, username }) => {
      const response = await api.renamePipe(pipeId, name, username)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pipe', variables.pipeId] })
    }
  })
}

// User Operations
export function useUpdateUserUndefined() {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['userUndefined'],
    queryFn: () => api.currentUserUndefined
  })
}

export function useCurrentError() {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['currentError'],
    queryFn: () => api.currentError
  })
}

// Auth Operations
export function useLogout() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async () => {
      await api.logout()
    },
    onSuccess: () => {
      queryClient.invalidateQueries() // Invalidate all queries
    }
  })
}

// Organization Operations
export function useUpdateOrganization() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ orgId, params }) => {
      const response = await api.updateOrganization(orgId, params)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organizations'] })
    }
  })
}

export function useDeleteOrganization() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (orgId) => {
      const response = await api.deleteOrganization(orgId)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organizations'] })
    }
  })
}
