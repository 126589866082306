const VegaUtils = () => {

    /**
     * Find all brush parameters in a view, ie. when drawing a box selection on the plot,
     * @param view: View API of the Vega Plot
     * @param base_param_name: Prefix of all signals used for filtering
     */
    const getCurrentBrushSelectionsFromView = (view, base_param_name = "param") => {
        // to find the box selection signal we first need to find the signal name
        // we hardcode the parameter names to start with "brush" in the backend
        return view.getState({signals: (name) => name.includes(base_param_name)}).signals
    }

    const getClickSignalFromView = (view) => {
        return view.getState({signals: (name) => name.includes("click")}).signals
    }

    /**
     * Vega plots may contain multiple datasets so we need to figure out which one is most relevat for the given selection.
     * Here we apply a few heuristics to find the best matching dataset.
     * @param {*} datasets straight out of view.data()
     * @param {*} selections straight out of getCurrentBrushSelectionsFromView()
     * @returns full dataset that matches the selection
     */
    const findBestMatchingDatasetForSelections = (datasets, selections) => {
        // we go through the selections one by one and try to find a dataset that matches
        for (const selection of selections) {
            let selectionColumn = Object.keys(selection)[0]
            let matchingDatasets = datasets.filter((dataset) => dataset.length > 0 && Object.keys(dataset[0]).includes(selectionColumn))
            if (matchingDatasets.length === 1) {
                return matchingDatasets[0]
            }
        }
        // in this case we have no information which is the right dataset
        // so we just choose the largest one
        return datasets.reduce((longest, current) => (current.length > longest.length ? current : longest), []);
    }
    
    const convertToDateIfNeeded = (value) => {
        // date strings look like this: "2016-04-07T00:00:00"
        if (typeof value === "string") {
            // check if the string matches the date format
            let parts = value.split("T")
            if (parts.length === 2) {
                let datePartContainsDashes = value.split("T")[0].split("-").length === 3
                let timePartContainsColons = value.split("T")[1].split(":").length === 3
                if (datePartContainsDashes && timePartContainsColons) {
                    let dateValue = new Date(value) 
                    if (!isNaN(dateValue)) {
                        // we parse the value to date then to unix before comparing
                        return dateValue.getTime();
                    }
                }
            }
        }
        return value
    }
    
    /**
     * Filter given plot data for entries that fulfill the selection ranges.
     * This implementation was stolen directly from https://observablehq.com/@visnup/vega-lite-data-out#selected
     * @param view: View API of the Vega Plot
     * @param selections: List of Objects with keys matching the keys on the objects in `data`. Values are arrays of `[min, max]` values.
     */
    const filterViewDataWithSelections = (view, selections, datasetNames=["data"]) => {
        // List of objects with keys matching the keys in `selection`. Each object represents one item on the plot
        // NOTE: we hardcode the dataset name "data" in the backend which is why we can confidently use it here
        let datasets = datasetNames.map((name) => view.data(name))
        if (datasets.length === 0) {
            return [] // throw an error in this case?
        }

        let data = findBestMatchingDatasetForSelections(datasets, selections)

        let filteredData = data.filter(d => {
            // first we loop through the selections
            for (const selection of selections) {
                // we loop through the key: value pairs of the selection to check if any requirement is missed
                for (const [key, filter] of Object.entries(selection)) {
                    // we can have numerical filters (min, max) or categorical filters (list of values)
                    if (filter.length === 2 && (typeof filter[0]) === "number") {
                        let [min, max] = filter
                        let value = convertToDateIfNeeded(d[key])
                        // if the value is NaN or outside the bounds of the selection range we drop it
                        if (isNaN(+value) || value < min || value > max) {
                            return false
                        }
                    } else {
                        // if the value is not in the list of allowed values we drop it
                        if (!filter.includes(d[key])) {
                            return false
                      }
                    }
                }
            }
            // if none of the checks fail this object is in the selected range so we keep it
            return true;
        })

        return filteredData
    }

    return {
        getCurrentBrushSelectionsFromView: getCurrentBrushSelectionsFromView,
        getClickSignalFromView: getClickSignalFromView,
        filterViewDataWithSelections: filterViewDataWithSelections
    }
}

export default VegaUtils;