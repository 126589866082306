import { trimNumericalPrecisionTo4Digits } from 'helper/trimNumericalPrecisionTo4Digits';
import { MaterialReactTable } from 'material-react-table';
import { useEffect, useState } from 'react';

const StaticDataTableComponent = ({
    data,
    initialSelectionState = {},
    handleFilterChange = undefined,
    onRowSelection = undefined,
    categoricalFilter = false,
    category = undefined,
    generateRowActionItems = undefined,
    disabledRows = undefined,
    isForeignPipe,
    isLoading = false
}) => {

    const enableRowSelectionBox = onRowSelection !== undefined
    const enableRowActionButton = generateRowActionItems !== undefined
    const enableRowSelection = (enableRowSelectionBox || categoricalFilter) && !isForeignPipe()

    const [rowSelection, setRowSelection] = useState(initialSelectionState)

    const columnsFromRows = (data, includeActionColumns) => {
        var columns = []
        if (data.length > 0) {
            columns = Object.keys(data[0])
        }
        // if we have row actions or row selection, we need to add those columns to the front
        if (includeActionColumns && enableRowActionButton && columns.indexOf('mrt-row-actions') === -1) {
            columns.splice(0, 0, 'mrt-row-actions')
        }
        if (includeActionColumns && enableRowSelection && columns.indexOf('mrt-row-select') === -1) {
            columns.splice(0, 0, 'mrt-row-select')
        }
        return columns
    }

    const columnOrder = (columns) => {
        // we want to move the `count` column to the end, so we first check if it's there and then move it if necessary
        let countIndex = columns.indexOf('count')
        if (countIndex !== -1) {
            columns.splice(countIndex, 1) // drop
            columns.push('count')
        }
        return columns
    }

    const columnDicts = (columns) => {
        if (data === undefined || data.length == 0) { return [] }
        let dicts = columns.map((column) => {
            var returnDict = {
                accessorKey: column,
                header: column
            }
            if (categoricalFilter) {
                returnDict.size = "150"
            }
            if (column === "count") {
                returnDict.header = "#"
                returnDict.size = "80"
            }
            return returnDict
        })
        return dicts
    }

    const isRowDisabled = (row) => {
        return disabledRows?.includes(row.original[category]) ?? false
    }

    useEffect(() => {
        if (initialSelectionState && JSON.stringify(rowSelection) !== JSON.stringify(initialSelectionState)) {
            setRowSelection(initialSelectionState)
        }
    }, [initialSelectionState])

    useEffect(() => {
        if (categoricalFilter) {
            handleFilterChange(rowSelection)
        } else {
            let selectedIndices = Object.keys(rowSelection)
            if (selectedIndices.length === 1) {
                onRowSelection(parseInt(selectedIndices[0]))
            }
        }
    }, [rowSelection])

    return (
        <MaterialReactTable
            // required props
            columns={columnDicts(columnOrder(columnsFromRows(data, false)))}
            data={trimNumericalPrecisionTo4Digits(data)}

            // set feature flags
            enableRowSelection={enableRowSelection}
            enableMultiRowSelection={categoricalFilter}
            enableStickyHeader
            enableTopToolbar={false}
            enableClickToCopy={false}
            enableColumnResizing
            enableFullScreenToggle={false}
            enableRowActions={enableRowActionButton}
            autoResetPageIndex={false}

            // row selection section
            selectAllMode={"all"}
            displayColumnDefOptions={{
                'mrt-row-select': { size: 20 },
                'mrt-row-actions': { size: 20 }
            }}
            getRowId={(row, index) => categoricalFilter ? row[category] : index}
            renderRowActionMenuItems={generateRowActionItems}
            onRowSelectionChange={setRowSelection}

            // state management
            initialState={{
                density: 'compact',
                rowSelection: rowSelection,
                columnOrder: columnOrder(columnsFromRows(data, true))
            }}
            muiSelectCheckboxProps={({ row }) => ({
                disabled: isLoading || isRowDisabled(row)
            })}
            muiPaginationProps={{
                showRowsPerPage: false
            }}
            muiBottomToolbarProps={({ table }) => {
                let elements = table.refs.bottomToolbarRef.current?.children[0]?.children[1]?.children[0]?.children
                var doubleHeight = false
                if (elements && elements.length > 1) {
                    doubleHeight = elements[1]?.offsetTop > 20
                }
                
                return {
                    sx: {
                        height: (doubleHeight) ? '80px' : '50px'
                    }
                }
            }}
            state={{
                rowSelection: rowSelection,
                isSaving: isLoading,
                columnOrder: columnOrder(columnsFromRows(data, true))
            }}
        />
    )
}

export default StaticDataTableComponent;