import { Stack, Typography, Box } from "@mui/material";
import { useIsForeignPipe } from "helper/UrlUtils";
import { useEffect, useState } from "react";
import { Vega } from "react-vega";
import { compile } from "vega-lite";
import Logger from "../../helper/Logger";
import useWindowDimensions from "../../helper/WindowDimensions";
import LoadingSnackbar from "../snackbars/LoadingSnackbar";
import { PlotDescriptionNewlineSeparator } from "helper/Constants";
import { usePresentationMode } from 'helper/PresentationModeContext';
import { useSpec } from '../../helper/useAPIs';

export const SpecLoadingPlotComponent = (
    {
        pipeId,
        nodeId,
        usernameFromPath,
        reload,
        isDashboardItem,
        onViewLoaded = (view) => { },
        patchVegaSpec = (spec) => { },
        setPlotDescriptions = () => { },
        use80percentWidth
    }
) => {
    const { height, width } = useWindowDimensions();
    const [spec, setSpec] = useState({});
    const isForeignPipe = useIsForeignPipe(usernameFromPath);
    const { isPresentationMode } = usePresentationMode();

    const { 
        data, 
        isLoading, 
        isRefetching,
        isError,
        refetch,
        error 
    } = useSpec({
        pipeId,
        blockId: nodeId,
        username: usernameFromPath,
        height: height * 0.75 - 200,
        width: use80percentWidth ? width * (2.8/4):(isDashboardItem ? width * (5/6): width * (3.32/5)),
        presentationMode: isPresentationMode
    });

    useEffect(() => {
        refetch()
    }, [reload])

    useEffect(() => {
        if (data) {
            try {
                const { spec: newSpec, description } = data;
                updateSpec(newSpec);
                const descriptions = description ? description.split(PlotDescriptionNewlineSeparator) : [];
                setPlotDescriptions(descriptions);
            } catch (err) {
                Logger.error("Error processing spec data:", err);
            }
        }
    }, [data]);

    const onNewView = (view) => {
        // the view is empty initially, so only set the listener once we have a valid spec
        if (view && view.getState().signals.background !== null) {
            onViewLoaded(view)
        }    
    }

/**
    * We need to "hotfix" the incoming vega-lite specs before rendering them. Here we do multiple things:
    * 1. Compile vega-lite spec to vega spec (to get access to raw signals)
    * 2. Send to parents for manual patching
    * @param spec: Incoming (from `hinten`) vega-lite spec
    */
    const updateSpec = (spec) => {
        // console.log(spec)
        let vgSpec = spec
        if (spec["$schema"].includes("vega-lite")) {
            vgSpec = compile(spec).spec
        }
        patchVegaSpec(vgSpec)
        if (isForeignPipe()) {
            vgSpec = {
                ...vgSpec, config: {
                    events: { view: false }, // Disable brush selection
                }
            }
        }
        setSpec(vgSpec);
    }

    return (
        <Stack direction="column">
            <LoadingSnackbar loading={isLoading || isRefetching} />
            {isError && (
                <Box sx={{ p: 2, color: 'error.main' }}>
                    <Typography variant="body1">
                        Error loading plot: {error?.message || 'Unknown error occurred'}
                    </Typography>
                </Box>
            )}
            <div id={`vega-container`}>
                {!isError && (
                    <Vega
                        spec={spec}
                        renderer="canvas"
                        onNewView={onNewView}
                        actions={false}
                    />
                )}
            </div>
        </Stack>
    )
}
