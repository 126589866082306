import { AccountCircle, SaveAs, Settings, ZoomInMap } from "@mui/icons-material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Box, Button, Tooltip, Typography, useTheme } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import ProfileMenu from "components/ProfileMenu";
import ClonePipeDialogComponent from "components/dialogs/ClonePipeDialogComponent";
import { encodePipeId, useIsForeignPipe, useURLParts } from "helper/UrlUtils";
import useFeatureFlags from "helper/useFeatureFlags";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiContext } from "../../helper/ApiContext";
import Logger from "../../helper/Logger";
import Route from "../../helper/Route";
import SaveSnapshotDialog from "../SaveSnapshotDialog";
import EnvironmentBadge from "./EnvironmentBadge";
import SettingsDrawerComponent from "./SettingsDrawerComponent";
import useTabStore from "../../stores/tabStore";
import { useUserProfile } from "helper/useAPIs";

export const ToolbarBaseComponent = ({ handleLogoClick, children }) => {
    const theme = useTheme();
    const { isKws } = useFeatureFlags()
    return (
        <MuiAppBar
            sx={{
                zIndex: 9998,
                background: theme.palette.primary.accent,
            }}
            style={{
                borderLeft: 'none',
                borderRight: 'none',
                borderTop: 'none',
            }}
        >
            <EnvironmentBadge />
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <Box p={1}>
                    <Button onClick={handleLogoClick} sx={{ textTransform: 'none', color: 'white' }}>
                        <img src={theme.img.logo.main} height={35} />
                        {isKws && <Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold', ml: 2 }} variant="h6">SmartPlot</Typography>}
                    </Button>
                </Box>

                {children}

            </Toolbar>
        </MuiAppBar>
    )
}

const ToolbarComponent = () => {
    const { api } = useContext(ApiContext);
    const { data: user} = useUserProfile()
    const { pipeId, nodeId, username, isDashboard, isTemplate } = useURLParts()
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null)
    const [saveAsDialogOpen, setSaveAsDialogOpen] = useState(false)
    const [settingsDrawerOpen, setSettingsDrawerOpen] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)
    const { currentUserIsAdmin, isKws } = useFeatureFlags()
    const activeTab = useTabStore((state) => state.activeTab);
    const setActiveTab = useTabStore((state) => state.setActiveTab);


    const shouldShowBackToPipeButton = (isDashboard === true) || nodeId !== undefined
    const shouldShowBackToDashboardButton = isDashboard === false
    const shouldShowButtonPortal = pipeId !== undefined && !isNaN(pipeId) && nodeId !== undefined && !isNaN(nodeId)
    const isForeignPipe = useIsForeignPipe(username);
    const shouldShowClonePipeButton = isForeignPipe() && pipeId !== undefined && !isNaN(pipeId)

    const handleSaveAsClick = (name, description) => {
        if (isNaN(pipeId)) {
            return;
        }
        api.savePipeSnapshot(pipeId, name, description)
            .then((response) => {
                // TODO: show success alert
                Logger.debug("Saved pipe snapshot: " + response.data)
            }).catch((error) => {
                // TODO: show error alert
                Logger.error("Could not save pipe snapshot: " + JSON.stringify(error))
            })
    }

    const PipeOrDashboardButton = ({ pipeId, destination }) => {
        const pipeUrl = encodePipeId(username, pipeId)
        return (
            <Tooltip title={`Switch to ${destination}`}>
                <IconButton
                    color="inherit"
                    onClick={() => {
                        navigate(`/${destination}/${pipeUrl}`)
                    }}
                >
                    {destination === "dashboard" ? <DashboardIcon /> : <ZoomInMap />}
                </IconButton>
            </Tooltip>
        )
    }

    const handleLogoClick = () => {
        navigate(Route.Home)
    }

    const handleCloneClick = () => {
        setDialogOpen(true);
    }

    // Add navigation handlers
    const handleMyPipelinesClick = () => {
        setActiveTab(0);
        navigate('/pipes/' + user?.username);
    };

    const handleTemplatesClick = () => {
        setActiveTab(1);
        navigate(`/pipes/${user?.username}`, { replace: true });
    };

    const NavButton = ({ path, label, onClick, active }) => (
        <Button
            color="inherit"
            onClick={onClick}
            sx={{ 
                color: 'white',
                textTransform: 'none',
                fontSize: '1rem',
                height: '64px', // Match toolbar height
                borderLeft: '1px solid rgba(255, 255, 255, 0.2)',
                borderRight: '1px solid rgba(255, 255, 255, 0.2)',
                borderBottom: active ? '3px solid white' : 'none',
                borderRadius: 0,
                px: 3,
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                }
            }}
        >
            <Box sx={{ 
                display: 'flex', 
                alignItems: 'center',
                gap: 1
            }}>
                <img 
                    src={`/kws/${path}.svg`} 
                    alt="" 
                    style={{ 
                        width: '20px', 
                        height: '20px',
                        filter: 'brightness(0) invert(1)' // Make SVG white
                    }} 
                />
                {label}
            </Box>
        </Button>
    );

    return (
        <ToolbarBaseComponent handleLogoClick={handleLogoClick}>
            {shouldShowBackToPipeButton &&
                <PipeOrDashboardButton pipeId={pipeId} destination="pipe" />}

            {shouldShowBackToDashboardButton &&
                <PipeOrDashboardButton pipeId={pipeId} destination="dashboard" />}

            {/* Navigation buttons */}
            {isKws && user?.username && (
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    ml: 4,
                    height: '100%'
                }}>
                    <NavButton 
                        path="pipes"
                        label="My Pipelines"
                        onClick={handleMyPipelinesClick}
                        active={activeTab === 0}
                    />
                    <NavButton 
                        path="templates"
                        label="Templates"
                        onClick={handleTemplatesClick}
                        active={activeTab === 1}
                    />
                </Box>
            )}

            <Box sx={{ flexGrow: 1 }} />

            {/* ------- Space ------ */}
            <div id="toolbar-button-portal" style={shouldShowButtonPortal ? {} : { display: 'none' }}></div>

            {process.env.REACT_APP_ENABLE_SNAPSHOT === "true" &&
                <Tooltip title="Save Snapshot">
                    <IconButton
                        color="inherit"
                        onClick={() => {
                            setSaveAsDialogOpen(true)
                        }}
                    >
                        <SaveAs />
                    </IconButton>
                </Tooltip>}
            {shouldShowClonePipeButton &&
                <Tooltip title="Clone Pipe">
                    <IconButton
                        color="inherit"
                        onClick={handleCloneClick}
                    >
                        <ContentCopyIcon />
                    </IconButton>
                </Tooltip>}
            <Tooltip title="Show Settings">
                <IconButton
                    color="inherit"
                    onClick={() => {
                        setSettingsDrawerOpen(!settingsDrawerOpen)
                    }}
                >
                    <Settings />
                </IconButton>
            </Tooltip>
            <Tooltip title="Profile">
                <IconButton
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget)
                    }}
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
            </Tooltip>

            {/* ------ Popups and slideovers ------ */}
            <SaveSnapshotDialog
                open={saveAsDialogOpen}
                onClose={() => setSaveAsDialogOpen(false)}
                onSave={handleSaveAsClick}
            />
            <SettingsDrawerComponent
                open={settingsDrawerOpen}
                onClose={() => setSettingsDrawerOpen(false)}
                pipeId={pipeId}
                usernameFromPath={username}
            />
            <ProfileMenu anchor={anchorEl} setAnchor={setAnchorEl} />
            <ClonePipeDialogComponent
                open={dialogOpen}
                setOpen={setDialogOpen}
                usernameFromPath={username}
                pipeId={pipeId}
                isTemplate={isTemplate}
            />

        </ToolbarBaseComponent>
    )
}

export default ToolbarComponent;