import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import LoginIcon from '@mui/icons-material/Login';
import Logout from '@mui/icons-material/Logout';
import { Menu, MenuItem, Stack, Typography } from "@mui/material";
import ListItemIcon from '@mui/material/ListItemIcon';
import LocalStorageService from 'helper/LocalStorageService';
import Route from 'helper/Route';
import { useUserProfile } from 'helper/useAPIs';
import { UserContext } from "helper/UserContext";
import { useContext } from "react";
import { useNavigate } from 'react-router-dom';
import useTabStore from 'stores/tabStore';

const LoginMenuItem = ({ setAnchor }) => {
    return (
        <MenuItem onClick={() => {
            setAnchor(null)
            window.location.replace(window.REACT_APP_API_ENDPOINT + Route.LoginRedirect)
        }}>
            <ListItemIcon>
                <LoginIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="body1">Login</Typography>
        </MenuItem>
    )
}

const LogoutMenuItem = ({ setAnchor }) => {
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const resetTab = useTabStore((state) => state.resetTab);

    return (
        <MenuItem onClick={() => {
            if (user) {
                LocalStorageService().clearToken()
                setUser(undefined)
            }
            resetTab();
            setAnchor(null)
            window.location.replace(window.REACT_APP_API_ENDPOINT + Route.LogoutRedirect)
        }}>
            <ListItemIcon>
                <Logout fontSize="small" />
            </ListItemIcon>
            <Typography variant="body1">Logout</Typography>
        </MenuItem>
    )
}

const UserInfo = ({ user }) => {
    return (
        <Stack direction="column" spacing={0}>
            <Typography variant="body1">{`Hello ${user?.name}!`}</Typography>
            <Typography variant="caption">{user?.organization?.name}</Typography>
        </Stack>
    )
}

const ProfileMenu = ({ anchor, setAnchor }) => {

    const { data: user } = useUserProfile();

    return (
        <Menu
            anchorEl={anchor}
            keepMounted
            open={Boolean(anchor)}
            onClose={() => {
                setAnchor(null)
            }}
            sx={{
                zIndex: 9999,
            }}
        >
            <MenuItem disabled>
                <ListItemIcon>
                    <EmojiPeopleIcon fontSize="small" />
                </ListItemIcon>
                {user ? <UserInfo user={user} /> : "Please login!"}
            </MenuItem>

            {user ? (<LogoutMenuItem setAnchor={setAnchor} />) : (<LoginMenuItem setAnchor={setAnchor} />)}
        </Menu>
    )
}

export default ProfileMenu