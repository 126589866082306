import { Box, Grid, Accordion, AccordionDetails, AccordionSummary, Typography, Button } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import DefaultPaper from "components/DefaultPaper";
import BrushSelectionListPlotComponent from "components/PlotComponents/BrushSelectionListPlotComponent";
import ConfigurePlotSidebarComponent from "components/PlotComponents/ConfigurePlotSidebarComponent";
import LoadingSnackbar from "components/snackbars/LoadingSnackbar";
import { PlotBlocks, TableBlocks, TableBlocksWithTableOnDashboard } from "helper/Constants";
import Logger from "helper/Logger";
import { useIsForeignPipe } from "helper/UrlUtils";
import { useEffect, useState, useRef } from "react";
import DataLoadingTableComponent from "../table/DataLoadingTableComponent";
import BlockDetailsComponent from "./BlockDetailsComponent";
import FilterPageComponent from "./FilterPageComponent";
import ListFilesPageComponent from "./ListFilesPageComponent";
import ExportPageComponent from "./ExportPageComponent";
import PlotSwapPageComponent from "./PlotSwapPageComponent";
import { useBlock } from 'helper/useAPIs';
import { useQueryClient } from '@tanstack/react-query';
import useFeatureFlags from "helper/useFeatureFlags";

const BlockPageComponent = ({
    pipeId,
    nodeId,
    usernameFromPath,
    reload,
    expanded, 
    toggleExpansion,
    overrideShowStaticPlot,
    showTable = true,
    isDashboardItem = false,
    hideBlockDetails = false,
    onSelectionChanged = () => { },
    use80percentWidth,
    isFilterCategoriesSideBlock = false, 
}) => {
    const [reloadCounter, setReloadCounter] = useState(0)
    const isForeignPipe = useIsForeignPipe(usernameFromPath);
    const [isAdminMode, setIsAdminMode] = useState(false);
    const queryClient = useQueryClient();

    const { 
        data: block, 
        isLoading, 
        isError, 
        error,
        refetch 
    } = useBlock(pipeId, nodeId, usernameFromPath);

    useEffect(() => {
        if (reload) {
            refetch();
        }
    }, [reload, refetch]);

    const blockType = (block) => (block?.blueprint?.type ?? "");
    const firstChildType = (block) => (block?.blueprint?.children[0]?.type ?? "");
    const [swapCounter, setSwapCounter] = useState(0)
    const headerRef = useRef(null)
    const [showStaticPlot, setShowStaticPlot] = useState(isDashboardItem)
    const { enableDashboardAccordions} = useFeatureFlags()

    const blockToShow = () => {
        if (blockType(block) === "plot_swap") {
            return block.parent_blocks[swapCounter % block.parent_blocks.length];
        }
        return block;
    };


    const handleAdminModeToggle = () => {
        setIsAdminMode(!isAdminMode);
    };

    // we mirror the reload flag to an internal one, because some child components need it but want to modify it here too
    useEffect(() => {
        setReloadCounter(reloadCounter + 1)
    }, [reload])

    useEffect(() => {
        if (overrideShowStaticPlot !== undefined) {
            setShowStaticPlot(overrideShowStaticPlot)
        }
    }, [overrideShowStaticPlot])

    const onSaveBlockConfiguration = (config) => {
        Logger.info("Configuration update triggered");
    };

    if (isLoading)
        return <LoadingSnackbar loading={true} />

    if (isError) {
        return (
            <DefaultPaper>
                <Box sx={{ p: 2, color: 'error.main' }}>
                    <Typography variant="h6">Error Loading Block</Typography>
                    <Typography variant="body1">
                        {error?.message || 'An unknown error occurred while loading the block.'}
                    </Typography>
                    <Button 
                        variant="contained" 
                        onClick={() => refetch()} 
                        sx={{ mt: 2 }}
                    >
                        Retry
                    </Button>
                </Box>
            </DefaultPaper>
        );
    }

    if (!block) {
        return (
            <DefaultPaper>
                <Typography variant="body1">
                    No block data available
                </Typography>
            </DefaultPaper>
        );
    }

    const handleHeaderClick = (event) => {
        let clickedOnHeaderOrChild = Array.from(headerRef.current.querySelectorAll("*")).includes(event.target)
        if (clickedOnHeaderOrChild)
            toggleExpansion();
    }

    let header = () => {
        var blockToShow = block
        if (blockType(block) === "plot_swap") {
            blockToShow = block.parent_blocks[swapCounter % block.parent_blocks.length]
        }
        return (
            <BlockDetailsComponent  
                pipeId={pipeId}
                usernameFromPath={usernameFromPath}
                block={blockToShow}
                isForeignPipe={isForeignPipe}
                isOnDashboard={isDashboardItem}
                onSelectionChanged={onSelectionChanged}
                showStaticPlot={showStaticPlot}
                reload={reload}
                onShowInteractivePlotClicked={() => {
                    setShowStaticPlot(!showStaticPlot)
                }}
                isFilterCategoriesSideBlock={isFilterCategoriesSideBlock}
            />
        )
    }

    let content = () => {
        let type = blockType(block)
        if (type === "import") {
            return <ListFilesPageComponent
                pipeId={pipeId}
                nodeId={nodeId}
                usernameFromPath={usernameFromPath}
                showTable={showTable}
                onSelectionChanged={onSelectionChanged}
            />
        } else if (PlotBlocks.includes(type)) {
            return <BrushSelectionListPlotComponent
                pipeId={pipeId}
                nodeId={nodeId}
                reloadCounter={reloadCounter}
                onSelectionChanged={onSelectionChanged}
                showTable={showTable}
                usernameFromPath={usernameFromPath}
                loadStaticPlot={showStaticPlot}
                isDashboardItem={isDashboardItem}
                use80percentWidth={use80percentWidth}
            />
        } else if (TableBlocks.includes(type)) {
            if (!showTable && !TableBlocksWithTableOnDashboard.includes(blockType(block))) {
                return (<></>);
            } else {
                return <DataLoadingTableComponent 
                    pipeId={pipeId} 
                    nodeId={nodeId}
                    block={block}
                    username={usernameFromPath} 
                    reload={reload}
                    config={block.configuration} 
                />
            }
        } else if (["filter_categories", "create_subgroup"].includes(type)) {
            return <FilterPageComponent
                pipeId={pipeId}
                nodeId={nodeId}
                blockType={type}
                usernameFromPath={usernameFromPath}
                reload={reload}
                showTable={showTable}
                onSelectionChanged={onSelectionChanged}
                isDashboardItem={isDashboardItem}
                isFilterCategoriesSideBlock={isFilterCategoriesSideBlock}
            />
        } else if (type === "export") {
            return <ExportPageComponent
                pipeId={pipeId}
                nodeId={nodeId}
                usernameFromPath={usernameFromPath}
                reload={reload}
                showTable={showTable}
            />
        } else if (type === "plot_swap") {
            return <PlotSwapPageComponent
                pipeId={pipeId}
                nodeId={nodeId}
                usernameFromPath={usernameFromPath}
                reload={reload}
                isDashboardItem={isDashboardItem}
                showTable={showTable}
                showStaticPlot={showStaticPlot}
                swapCounter={swapCounter}
                setSwapCounter={setSwapCounter}
                onSelectionChanged={onSelectionChanged}
                parentBlocks={block.parent_blocks}
            />
        } else {
            return (<div style={{ margin: 10 }}>Loading...</div>)
        }
    }

    let properlyWrappedContent = () => {
        if (hideBlockDetails) {
            return (
                <div style={{ padding: '10px' }}>
                    {content()}
                </div>
            )
        } else if (isDashboardItem && enableDashboardAccordions && !isFilterCategoriesSideBlock) {
            return (
                <div
                    style={{
                        marginBottom: '10px'
                    }}
                >
                    <Accordion
                        key={block.id}
                        expanded={expanded}
                        onClick={handleHeaderClick}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            ref={headerRef}
                        >
                            {header()}
                        </AccordionSummary>
                        <AccordionDetails>
                            {content()}
                        </AccordionDetails>
                    </Accordion>
                </div>
            )
        } else {
            let hideContent = isDashboardItem && (TableBlocks.includes(blockType(block)) && !TableBlocksWithTableOnDashboard.includes(blockType(block)))
            return (
                <div 
                    key={block.id} 
                    style={{ 
                        marginTop: isDashboardItem ? '0px' : '55px',
                        marginBottom: isDashboardItem ? '20px' : '0px',
                        border: '1px solid black',
                        backgroundColor: 'white',
                        width: (isFilterCategoriesSideBlock) ? '16vw' : (isDashboardItem && use80percentWidth) ? '79.5vw' : (isDashboardItem ? '96vw' : '100%')
                    }}
                >
                    <div style={{ borderBottom: '1px solid black', padding: '10px' }}>
                        {header()}
                    </div>
                    {!hideContent && <div style={{ padding: '10px' }}>
                        {content()}
                    </div>}
                </div>
            )
        }
    }

    let showSidebar = block && blockType(block) !== "import" && !isDashboardItem && !hideBlockDetails && blockType(block) !== "export";
    if (showSidebar) {
        return (
            <Grid container spacing={2} sx={{ width: "97vw" }}>
                <LoadingSnackbar loading={isLoading} />
                <Grid item xs={10}>
                    {properlyWrappedContent()}
                </Grid>
                <Grid item xs={2} sx={{ maxHeight: '90px' }}>
                    {block && showTable &&
                        <ConfigurePlotSidebarComponent
                            onSaveConfig={(config) => onSaveBlockConfiguration(config)}
                            isForeignPipe={isForeignPipe}
                            initialConfig={blockToShow().configuration}
                            isShowTableBlock={blockType(blockToShow()) === "show_table"}
                            isAdminMode={isAdminMode}
                            onAdminModeToggle={handleAdminModeToggle}
                            pipeId={pipeId}
                            nodeId={blockToShow()?.flow_id}
                            username={usernameFromPath}
                        />}
                </Grid>
            </Grid>
        )
    } else if (block) {
        return properlyWrappedContent()
    }
}

export default BlockPageComponent